var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "configure g-px py-8 md:py-16" }, [
    _c(
      "button",
      {
        staticClass: "btn-close",
        on: {
          click: function ($event) {
            $event.preventDefault()
            return _vm.$emit("close")
          },
        },
      },
      [
        _c(
          "svg",
          {
            attrs: {
              viewBox: "0 0 48 48",
              xmlns: "http://www.w3.org/2000/svg",
            },
          },
          [
            _c(
              "g",
              {
                attrs: {
                  id: "Symbols",
                  stroke: "none",
                  "stroke-width": "1",
                  fill: "none",
                  "fill-rule": "evenodd",
                },
              },
              [
                _c("g", { attrs: { id: "icon/close" } }, [
                  _c("g", { attrs: { id: "Group-2" } }, [
                    _c("g", { attrs: { id: "Group-19", fill: "#FFFFFF" } }, [
                      _c("circle", {
                        attrs: { id: "Oval", cx: "24", cy: "24", r: "24" },
                      }),
                    ]),
                    _vm._v(" "),
                    _c(
                      "g",
                      {
                        attrs: {
                          id: "Group",
                          transform: "translate(15.000000, 15.000000)",
                          fill: "#001e62",
                        },
                      },
                      [
                        _c("rect", {
                          attrs: {
                            id: "Rectangle",
                            transform:
                              "translate(9.000000, 9.500000) scale(1, -1) rotate(45.000000) translate(-9.000000, -9.500000) ",
                            x: "-3",
                            y: "9",
                            width: "24",
                            height: "1",
                            rx: "0.5",
                          },
                        }),
                        _vm._v(" "),
                        _c("rect", {
                          attrs: {
                            id: "Rectangle",
                            transform:
                              "translate(9.000000, 9.500000) scale(1, -1) rotate(-45.000000) translate(-9.000000, -9.500000) ",
                            x: "-3",
                            y: "9",
                            width: "24",
                            height: "1",
                            rx: "0.5",
                          },
                        }),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        ),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row-c-sm flex flex-col" }, [
      _c("div", { staticClass: "steps" }, [
        _c(
          "a",
          { staticClass: "step", class: _vm.activeStep === 1 ? "active" : "" },
          [
            _c("div", { staticClass: "step-number" }, [_vm._v("1")]),
            _vm._v(" "),
            _c("label", [_vm._v(_vm._s(_vm.$t("Personalisatie")))]),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "step", class: _vm.activeStep === 2 ? "active" : "" },
          [
            _c("div", { staticClass: "step-number" }, [_vm._v("2")]),
            _vm._v(" "),
            _c("label", [_vm._v(_vm._s(_vm.$t("Verzending")))]),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          { staticClass: "step", class: _vm.activeStep === 3 ? "active" : "" },
          [
            _c("div", { staticClass: "step-number" }, [_vm._v("3")]),
            _vm._v(" "),
            _c("label", [_vm._v(_vm._s(_vm.$t("Samenvatting")))]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "panel-wrapper" }, [
        _c(
          "main",
          { staticClass: "panel-main" },
          [
            _c("h3", { staticClass: "mb-12" }, [_vm._v(_vm._s(_vm.stepTitle))]),
            _vm._v(" "),
            _vm.item
              ? [
                  _c("div", { staticClass: "item" }, [
                    _c("div", { staticClass: "item-thumbnail" }, [
                      _vm.item.productThumbnail
                        ? _c("img", {
                            attrs: {
                              src: _vm.item.productThumbnail,
                              alt: _vm.item.snapshot.product.title,
                            },
                          })
                        : _c("img", {
                            attrs: {
                              src: "/assets/svg/no-picture.svg",
                              alt: _vm.item.snapshot.product.title,
                            },
                          }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "item-info" }, [
                      _c("div", { staticClass: "nm:mr-4" }, [
                        _c("h4", { staticClass: "mb-2" }, [
                          _vm._v(_vm._s(_vm.item.snapshot.product.title)),
                        ]),
                        _vm._v(" "),
                        _c("p", { staticClass: "mb-2" }, [
                          _vm._v(_vm._s(_vm.item.snapshot.product.description)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          { staticClass: "text-sm" },
                          [
                            _vm.item.onSale
                              ? [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(_vm.item.qty) +
                                      " " +
                                      _vm._s(_vm.$t("stuk(s)")) +
                                      " - "
                                  ),
                                  _c(
                                    "span",
                                    { staticClass: "text-sm line-through" },
                                    [_vm._v(_vm._s(_vm.item.priceAsCurrency))]
                                  ),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.item.salePriceAsCurrency) +
                                      " " +
                                      _vm._s(_vm.$t("per stuk")) +
                                      "\n                                    "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n                                        " +
                                      _vm._s(_vm.item.qty) +
                                      " " +
                                      _vm._s(_vm.$t("stuk(s)")) +
                                      " - " +
                                      _vm._s(_vm.item.priceAsCurrency) +
                                      " " +
                                      _vm._s(_vm.$t("per stuk")) +
                                      "\n                                    "
                                  ),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.chosenOptions.personalisation
                          ? _c("p", { staticClass: "text-sm" }, [
                              _c("b", [
                                _vm._v(_vm._s(_vm.$t("Personalisatie")) + ":"),
                              ]),
                              _vm._v(
                                "\n                                    " +
                                  _vm._s(
                                    _vm.chosenOptions.personalisation.title
                                  ) +
                                  "\n                                    " +
                                  _vm._s(
                                    _vm.chosenOptions.personalisation
                                      .salePrice > 0
                                      ? "- " +
                                          _vm.currency(
                                            _vm.chosenOptions.personalisation
                                              .salePrice
                                          ) +
                                          " " +
                                          _vm.$t("per stuk")
                                      : ""
                                  ) +
                                  "\n                                "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.chosenOptions.delivery
                          ? _c("p", { staticClass: "text-sm" }, [
                              _c("b", [
                                _vm._v(_vm._s(_vm.$t("Verzending")) + ":"),
                              ]),
                              _vm._v(
                                " " + _vm._s(this.chosenOptions.delivery.label)
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "font-normal text-2xl mb-4 nm:mb-0" },
                        [_vm._v(_vm._s(_vm.item.subtotalAsCurrency))]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeStep === 1,
                          expression: "activeStep === 1",
                        },
                      ],
                      ref: "step-1",
                    },
                    [
                      _c("div", { staticClass: "panel-step form" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "panel__field w-full nm:w-2/5 mb-4 nm:mb-0",
                            class:
                              Object.keys(_vm.options.personalisation).length >
                              1
                                ? "nm:w-2/5 nm:pr-8"
                                : "nm:w-full",
                          },
                          [
                            _c("label", { attrs: { for: "qty-step-1" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("Aantal")) +
                                  " (" +
                                  _vm._s(
                                    `${_vm.$t("per")} ${
                                      _vm.options.qtyInCarton > 1
                                        ? _vm.options.qtyInCarton
                                        : ""
                                    } ${
                                      _vm.options.qtyInCarton > 1
                                        ? _vm.$t("stuk(s)")
                                        : _vm.$t("stuk")
                                    }`
                                  ) +
                                  ")"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              attrs: { id: "qty-step-1", type: "number" },
                              domProps: { value: _vm.item.qty },
                              on: {
                                change: function ($event) {
                                  return _vm.qtyChange(_vm.index, $event)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        Object.keys(_vm.options.personalisation).length > 1
                          ? _c(
                              "div",
                              { staticClass: "panel__field w-full nm:w-3/5" },
                              [
                                _c(
                                  "label",
                                  { attrs: { for: "personalisation" } },
                                  [_vm._v(_vm._s(_vm.$t("Personalisatie")))]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "select-styling" }, [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.personalisation,
                                          expression: "personalisation",
                                        },
                                      ],
                                      staticClass: "overflow-hidden",
                                      attrs: { id: "personalisation" },
                                      on: {
                                        change: [
                                          function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.personalisation = $event.target
                                              .multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                          _vm.showPersonalisationInfo,
                                        ],
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "Selecteer een personalisatie"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.options.personalisation,
                                        function (personalise, key) {
                                          return _c(
                                            "option",
                                            { domProps: { value: key } },
                                            [
                                              _vm._v(
                                                _vm._s(personalise.title) +
                                                  "\n                                        "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.chosenOptions.personalisation
                          ? _c("div", { staticClass: "mt-4 nm:mt-8 w-full" }, [
                              _vm.chosenOptions.personalisation.description ||
                              _vm.chosenOptions.personalisation.packshots
                                ? _c("div", { staticClass: "flex" }, [
                                    _vm.chosenOptions.personalisation.packshots
                                      ? _c(
                                          "div",
                                          { staticClass: "w-1/4 pr-8" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "swiper-container",
                                                attrs: {
                                                  id: "personalisation-swiper",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "swiper-wrapper",
                                                  },
                                                  _vm._l(
                                                    _vm.chosenOptions
                                                      .personalisation
                                                      .packshots,
                                                    function (packshot) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "swiper-slide",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: packshot.thumbUrl,
                                                              alt: packshot.title,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass:
                                                    "swiper-pagination",
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "item-description wysiwyg",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.chosenOptions.personalisation
                                            .description
                                        ),
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.chosenOptions.personalisation.cutsheet
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn flex-grow mr-4 mb-6 w-full",
                                      attrs: {
                                        href: _vm.chosenOptions.personalisation
                                          .cutsheet.url,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Download kapvorm")))]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "mb-4" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass:
                                        "block uppercase text-purple-500 text-xs mb-2",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("Gewenste leveringsdatum")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("datepicker", {
                                    attrs: {
                                      format: _vm.desiredDeliveryDate.format,
                                      "disabled-dates": {
                                        to: _vm.calcDesiredDeliveryDateStart,
                                        days: [6, 0],
                                      },
                                      language: _vm.locale,
                                      "monday-first": true,
                                      "full-month-name": true,
                                    },
                                    model: {
                                      value: _vm.desiredDeliveryDateSelected,
                                      callback: function ($$v) {
                                        _vm.desiredDeliveryDateSelected = $$v
                                      },
                                      expression: "desiredDeliveryDateSelected",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.chosenOptions.personalisation.deliveryTerm
                                ? _c("small", { staticClass: "caption mt-4" }, [
                                    _c("span", { staticClass: "font-bold" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.chosenOptions.personalisation
                                            .deliveryTerm
                                        )
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeStep === 2,
                          expression: "activeStep === 2",
                        },
                      ],
                      ref: "step-2",
                    },
                    [
                      _c("div", { staticClass: "panel-step form" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "panel__field w-full nm:w-2/5 mb-4 nm:mb-0 nm:pr-8",
                          },
                          [
                            _c("label", { attrs: { for: "qty-step-2" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("Aantal")) +
                                  " (" +
                                  _vm._s(
                                    `${_vm.$t("per")} ${
                                      _vm.options.qtyInCarton > 1
                                        ? _vm.options.qtyInCarton
                                        : ""
                                    } ${
                                      _vm.options.qtyInCarton > 1
                                        ? _vm.$t("stuks")
                                        : _vm.$t("stuk")
                                    }`
                                  ) +
                                  ")"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              attrs: { id: "qty-step-2", type: "number" },
                              domProps: { value: _vm.item.qty },
                              on: {
                                change: function ($event) {
                                  return _vm.qtyChange(_vm.index, $event)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "panel__field w-full nm:w-3/5" },
                          [
                            _c("label", { attrs: { for: "delivery" } }, [
                              _vm._v(_vm._s(_vm.$t("Verzending"))),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "select-styling" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.delivery,
                                      expression: "delivery",
                                    },
                                  ],
                                  staticClass: "overflow-hidden",
                                  attrs: { id: "delivery" },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.delivery = $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.showDeliveryInfo,
                                    ],
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "" } }, [
                                    _vm._v(
                                      _vm._s(_vm.$t("Selecteer een verzending"))
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.deliveryTypes,
                                    function (option, id) {
                                      return _c(
                                        "option",
                                        { domProps: { value: id } },
                                        [
                                          _vm._v(
                                            _vm._s(option.label) +
                                              "\n                                        "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.chosenOptions.delivery
                          ? _c(
                              "div",
                              { staticClass: "mt-4 nm:mt-8 w-full" },
                              [
                                _c("div", {
                                  staticClass: "item-description wysiwyg",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.chosenOptions.delivery.description
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _vm.delivery === "single-address"
                                  ? [
                                      _c("validation-observer", {
                                        ref: "single-address-validation",
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({
                                                errors,
                                                invalid,
                                              }) {
                                                return [
                                                  _vm.shippingAddress.minimized
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "address--minimized flex w-full justify-between items-center mb-8",
                                                        },
                                                        [
                                                          _c("p", [
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm
                                                                    .shippingAddress
                                                                    .firstName
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .shippingAddress
                                                                      .lastName
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    _vm
                                                                      .shippingAddress
                                                                      .businessName
                                                                      ? "- " +
                                                                          _vm
                                                                            .shippingAddress
                                                                            .businessName
                                                                      : ""
                                                                  )
                                                              ),
                                                            ]),
                                                            _c("br"),
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  _vm
                                                                    .shippingAddress
                                                                    .address1
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .shippingAddress
                                                                    .address2
                                                                ) +
                                                                _vm._s(
                                                                  _vm
                                                                    .shippingAddress
                                                                    .address3
                                                                    ? " bus " +
                                                                        _vm
                                                                          .shippingAddress
                                                                          .address3
                                                                    : ""
                                                                ) +
                                                                ",\n                                                " +
                                                                _vm._s(
                                                                  _vm
                                                                    .shippingAddress
                                                                    .zipCode
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .shippingAddress
                                                                    .city
                                                                ) +
                                                                ", " +
                                                                _vm._s(
                                                                  _vm
                                                                    .shippingAddress
                                                                    .country
                                                                )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn mt-4 md:ml-4 md:mt-0 md:self-center",
                                                              attrs: {
                                                                type: "submit",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.showAddress(
                                                                      "single-address"
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Aanpassen"
                                                                    )
                                                                  ) +
                                                                  "\n                                            "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "address--form flex flex-wrap -mx-2 mb-8",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input w-full md:w-1/2 px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for: "firstName",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Voornaam"
                                                                      )
                                                                    ) + " *"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    name: "Voornaam",
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm
                                                                                            .shippingAddress
                                                                                            .firstName,
                                                                                        expression:
                                                                                          "shippingAddress.firstName",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "w-full",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "text",
                                                                                      id: "firstName",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .shippingAddress
                                                                                          .firstName,
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        if (
                                                                                          $event
                                                                                            .target
                                                                                            .composing
                                                                                        )
                                                                                          return
                                                                                        _vm.$set(
                                                                                          _vm.shippingAddress,
                                                                                          "firstName",
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              errors.length
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "error",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      2479321456
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input w-full md:w-1/2 px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for: "lastName",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Achternaam"
                                                                      )
                                                                    ) + " *"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    name: "Achternaam",
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm
                                                                                            .shippingAddress
                                                                                            .lastName,
                                                                                        expression:
                                                                                          "shippingAddress.lastName",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "w-full",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "text",
                                                                                      id: "lastName",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .shippingAddress
                                                                                          .lastName,
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        if (
                                                                                          $event
                                                                                            .target
                                                                                            .composing
                                                                                        )
                                                                                          return
                                                                                        _vm.$set(
                                                                                          _vm.shippingAddress,
                                                                                          "lastName",
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              errors.length
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "error",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      1763118016
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input w-full px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for: "businessName",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Bedrijf"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    name: "Bedrijf",
                                                                    rules: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm
                                                                                            .shippingAddress
                                                                                            .businessName,
                                                                                        expression:
                                                                                          "shippingAddress.businessName",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "w-full",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "text",
                                                                                      id: "businessName",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .shippingAddress
                                                                                          .businessName,
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        if (
                                                                                          $event
                                                                                            .target
                                                                                            .composing
                                                                                        )
                                                                                          return
                                                                                        _vm.$set(
                                                                                          _vm.shippingAddress,
                                                                                          "businessName",
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              errors.length
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "error",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      2231587180
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input w-full md:w-8/12 px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for: "address1",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Straat"
                                                                      )
                                                                    ) + " *"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    name: "Straat",
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm
                                                                                            .shippingAddress
                                                                                            .address1,
                                                                                        expression:
                                                                                          "shippingAddress.address1",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "w-full",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "text",
                                                                                      id: "address1",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .shippingAddress
                                                                                          .address1,
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        if (
                                                                                          $event
                                                                                            .target
                                                                                            .composing
                                                                                        )
                                                                                          return
                                                                                        _vm.$set(
                                                                                          _vm.shippingAddress,
                                                                                          "address1",
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              errors.length
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "error",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      2104326602
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input w-1/2 md:w-2/12 px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for: "address2",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Nr."
                                                                      )
                                                                    ) + " *"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    name: "Nr.",
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm
                                                                                            .shippingAddress
                                                                                            .address2,
                                                                                        expression:
                                                                                          "shippingAddress.address2",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "w-full",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "text",
                                                                                      id: "address2",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .shippingAddress
                                                                                          .address2,
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        if (
                                                                                          $event
                                                                                            .target
                                                                                            .composing
                                                                                        )
                                                                                          return
                                                                                        _vm.$set(
                                                                                          _vm.shippingAddress,
                                                                                          "address2",
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              errors.length
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "error",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      1315406921
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input w-1/2 md:w-2/12 px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for: "address3",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Bus"
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    name: "Bus",
                                                                    rules: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm
                                                                                            .shippingAddress
                                                                                            .address3,
                                                                                        expression:
                                                                                          "shippingAddress.address3",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "w-full",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "text",
                                                                                      id: "address3",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .shippingAddress
                                                                                          .address3,
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        if (
                                                                                          $event
                                                                                            .target
                                                                                            .composing
                                                                                        )
                                                                                          return
                                                                                        _vm.$set(
                                                                                          _vm.shippingAddress,
                                                                                          "address3",
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              errors.length
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "error",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      992663496
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input w-full md:w-5/12 px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for: "city",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Stad"
                                                                      )
                                                                    ) + " *"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    name: "Stad",
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm
                                                                                            .shippingAddress
                                                                                            .city,
                                                                                        expression:
                                                                                          "shippingAddress.city",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "w-full",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "text",
                                                                                      id: "city",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .shippingAddress
                                                                                          .city,
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        if (
                                                                                          $event
                                                                                            .target
                                                                                            .composing
                                                                                        )
                                                                                          return
                                                                                        _vm.$set(
                                                                                          _vm.shippingAddress,
                                                                                          "city",
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              errors.length
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "error",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      451276618
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input w-full md:w-3/12 px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for: "zipCode",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Postcode"
                                                                      )
                                                                    ) + " *"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    name: "Postcode",
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "input",
                                                                                {
                                                                                  directives:
                                                                                    [
                                                                                      {
                                                                                        name: "model",
                                                                                        rawName:
                                                                                          "v-model",
                                                                                        value:
                                                                                          _vm
                                                                                            .shippingAddress
                                                                                            .zipCode,
                                                                                        expression:
                                                                                          "shippingAddress.zipCode",
                                                                                      },
                                                                                    ],
                                                                                  staticClass:
                                                                                    "w-full",
                                                                                  attrs:
                                                                                    {
                                                                                      type: "text",
                                                                                      id: "zipCode",
                                                                                    },
                                                                                  domProps:
                                                                                    {
                                                                                      value:
                                                                                        _vm
                                                                                          .shippingAddress
                                                                                          .zipCode,
                                                                                    },
                                                                                  on: {
                                                                                    input:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        if (
                                                                                          $event
                                                                                            .target
                                                                                            .composing
                                                                                        )
                                                                                          return
                                                                                        _vm.$set(
                                                                                          _vm.shippingAddress,
                                                                                          "zipCode",
                                                                                          $event
                                                                                            .target
                                                                                            .value
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              errors.length
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "error",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      2010703459
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "input w-full md:w-4/12 px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "label",
                                                                {
                                                                  attrs: {
                                                                    for: "country",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Land"
                                                                      )
                                                                    ) + " *"
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    name: "Land",
                                                                    rules:
                                                                      "required",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "default",
                                                                          fn: function ({
                                                                            errors,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "select-styling w-full",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "select",
                                                                                    {
                                                                                      directives:
                                                                                        [
                                                                                          {
                                                                                            name: "model",
                                                                                            rawName:
                                                                                              "v-model",
                                                                                            value:
                                                                                              _vm
                                                                                                .shippingAddress
                                                                                                .country,
                                                                                            expression:
                                                                                              "shippingAddress.country",
                                                                                          },
                                                                                        ],
                                                                                      attrs:
                                                                                        {
                                                                                          id: "country",
                                                                                        },
                                                                                      on: {
                                                                                        change:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            var $$selectedVal =
                                                                                              Array.prototype.filter
                                                                                                .call(
                                                                                                  $event
                                                                                                    .target
                                                                                                    .options,
                                                                                                  function (
                                                                                                    o
                                                                                                  ) {
                                                                                                    return o.selected
                                                                                                  }
                                                                                                )
                                                                                                .map(
                                                                                                  function (
                                                                                                    o
                                                                                                  ) {
                                                                                                    var val =
                                                                                                      "_value" in
                                                                                                      o
                                                                                                        ? o._value
                                                                                                        : o.value
                                                                                                    return val
                                                                                                  }
                                                                                                )
                                                                                            _vm.$set(
                                                                                              _vm.shippingAddress,
                                                                                              "country",
                                                                                              $event
                                                                                                .target
                                                                                                .multiple
                                                                                                ? $$selectedVal
                                                                                                : $$selectedVal[0]
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "option",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              value:
                                                                                                "",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "Selecteer"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "option",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              value:
                                                                                                "be",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "België"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "option",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              value:
                                                                                                "nl",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "Nederland"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                      _vm._v(
                                                                                        " "
                                                                                      ),
                                                                                      _c(
                                                                                        "option",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              value:
                                                                                                "anywhere",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              _vm.$t(
                                                                                                "Andere"
                                                                                              )
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              errors.length
                                                                                ? _c(
                                                                                    "span",
                                                                                    {
                                                                                      staticClass:
                                                                                        "error",
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          errors[0]
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      false,
                                                                      3088486290
                                                                    ),
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "w-full flex justify-end mt-4 px-2",
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn bg-red-600 text-white border-red-600 w-full md:w-auto",
                                                                  attrs: {
                                                                    type: "submit",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        return _vm.validateAddress(
                                                                          "single-address",
                                                                          0
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Bevestig adres"
                                                                      )
                                                                    ) +
                                                                      "\n                                                "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          2674829265
                                        ),
                                      }),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.delivery === "multiple-addresses"
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "shipping-addresses-wrapper",
                                        },
                                        [
                                          _vm._l(
                                            _vm.shippingAddresses,
                                            function (shippingAddress, index) {
                                              return _c("div", { key: index }, [
                                                shippingAddress.minimized
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "address--minimized",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "address--minimized__qty text-sm",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                shippingAddress.qty
                                                              ) +
                                                                " " +
                                                                _vm._s(
                                                                  shippingAddress.qty >
                                                                    1
                                                                    ? _vm.$t(
                                                                        "stuk(s)"
                                                                      )
                                                                    : _vm.$t(
                                                                        "stuk"
                                                                      )
                                                                )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "flex-grow text-sm",
                                                          },
                                                          [
                                                            _c("b", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  shippingAddress.firstName
                                                                ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    shippingAddress.lastName
                                                                  ) +
                                                                  " " +
                                                                  _vm._s(
                                                                    shippingAddress.businessName
                                                                      ? "- " +
                                                                          shippingAddress.businessName
                                                                      : ""
                                                                  )
                                                              ),
                                                            ]),
                                                            _c("br"),
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  shippingAddress.address1
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  shippingAddress.address2
                                                                ) +
                                                                _vm._s(
                                                                  shippingAddress.address3
                                                                    ? " bus " +
                                                                        shippingAddress.address3
                                                                    : ""
                                                                ) +
                                                                ",\n                                                    " +
                                                                _vm._s(
                                                                  shippingAddress.zipCode
                                                                ) +
                                                                " " +
                                                                _vm._s(
                                                                  shippingAddress.city
                                                                ) +
                                                                ", " +
                                                                _vm._s(
                                                                  shippingAddress.country
                                                                ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn mt-4 md:ml-4 md:mt-0 md:self-center",
                                                            attrs: {
                                                              type: "submit",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.showAddress(
                                                                  "multiple-addresses",
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "Aanpassen"
                                                                )
                                                              ) +
                                                                "\n                                                "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    )
                                                  : _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "address--form",
                                                      },
                                                      [
                                                        _c(
                                                          "validation-observer",
                                                          {
                                                            ref:
                                                              "multiple-addresses-" +
                                                              index +
                                                              "-validation",
                                                            refInFor: true,
                                                            staticClass:
                                                              "flex flex-wrap -mx-2 mb-8",
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "default",
                                                                  fn: function ({
                                                                    errors,
                                                                    invalid,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input w-full md:w-1/2 px-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  for:
                                                                                    "firstName" +
                                                                                    index,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Voornaam"
                                                                                  )
                                                                                ) +
                                                                                  " *"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "validation-provider",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "Voornaam",
                                                                                  rules:
                                                                                    "required",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function ({
                                                                                        errors,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      shippingAddress.firstName,
                                                                                                    expression:
                                                                                                      "shippingAddress.firstName",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "w-full",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  id:
                                                                                                    "firstName" +
                                                                                                    index,
                                                                                                  name: "shippingAddresses[][firstName]",
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value:
                                                                                                    shippingAddress.firstName,
                                                                                                },
                                                                                              on: {
                                                                                                input:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      shippingAddress,
                                                                                                      "firstName",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          errors.length
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "error",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      errors[0]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input w-full md:w-1/2 px-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  for:
                                                                                    "lastName" +
                                                                                    index,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Achternaam"
                                                                                  )
                                                                                ) +
                                                                                  " *"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "validation-provider",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "Achternaam",
                                                                                  rules:
                                                                                    "required",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function ({
                                                                                        errors,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      shippingAddress.lastName,
                                                                                                    expression:
                                                                                                      "shippingAddress.lastName",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "w-full",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  id:
                                                                                                    "lastName" +
                                                                                                    index,
                                                                                                  name: "shippingAddresses[][lastName]",
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value:
                                                                                                    shippingAddress.lastName,
                                                                                                },
                                                                                              on: {
                                                                                                input:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      shippingAddress,
                                                                                                      "lastName",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          errors.length
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "error",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      errors[0]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input w-full px-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  for:
                                                                                    "businessName" +
                                                                                    index,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Bedrijf"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "validation-provider",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "Bedrijf",
                                                                                  rules:
                                                                                    "",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function ({
                                                                                        errors,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      shippingAddress.businessName,
                                                                                                    expression:
                                                                                                      "shippingAddress.businessName",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "w-full",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  id:
                                                                                                    "businessName" +
                                                                                                    index,
                                                                                                  name: "shippingAddresses[][businessName]",
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value:
                                                                                                    shippingAddress.businessName,
                                                                                                },
                                                                                              on: {
                                                                                                input:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      shippingAddress,
                                                                                                      "businessName",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          errors.length
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "error",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      errors[0]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input w-full md:w-6/12 px-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  for:
                                                                                    "address1" +
                                                                                    index,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Straat"
                                                                                  )
                                                                                ) +
                                                                                  " *"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "validation-provider",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "Straat",
                                                                                  rules:
                                                                                    "required",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function ({
                                                                                        errors,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      shippingAddress.address1,
                                                                                                    expression:
                                                                                                      "shippingAddress.address1",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "w-full",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  id:
                                                                                                    "address1" +
                                                                                                    index,
                                                                                                  name: "shippingAddresses[][address1]",
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value:
                                                                                                    shippingAddress.address1,
                                                                                                },
                                                                                              on: {
                                                                                                input:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      shippingAddress,
                                                                                                      "address1",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          errors.length
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "error",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      errors[0]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input w-1/2 md:w-3/12 px-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  for:
                                                                                    "address2" +
                                                                                    index,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Nr."
                                                                                  )
                                                                                ) +
                                                                                  " *"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "validation-provider",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "Nr.",
                                                                                  rules:
                                                                                    "required",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function ({
                                                                                        errors,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      shippingAddress.address2,
                                                                                                    expression:
                                                                                                      "shippingAddress.address2",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "w-full",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  id:
                                                                                                    "address2" +
                                                                                                    index,
                                                                                                  name: "shippingAddresses[][address2]",
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value:
                                                                                                    shippingAddress.address2,
                                                                                                },
                                                                                              on: {
                                                                                                input:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      shippingAddress,
                                                                                                      "address2",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          errors.length
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "error",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      errors[0]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input w-1/2 md:w-3/12 px-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  for:
                                                                                    "address3" +
                                                                                    index,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Bus"
                                                                                  )
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "validation-provider",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "Bus",
                                                                                  rules:
                                                                                    "",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function ({
                                                                                        errors,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      shippingAddress.address3,
                                                                                                    expression:
                                                                                                      "shippingAddress.address3",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "w-full",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  id:
                                                                                                    "address3" +
                                                                                                    index,
                                                                                                  name: "shippingAddresses[][address3]",
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value:
                                                                                                    shippingAddress.address3,
                                                                                                },
                                                                                              on: {
                                                                                                input:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      shippingAddress,
                                                                                                      "address3",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          errors.length
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "error",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      errors[0]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input w-full md:w-5/12 px-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  for:
                                                                                    "city" +
                                                                                    index,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Stad"
                                                                                  )
                                                                                ) +
                                                                                  " *"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "validation-provider",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "Voornaam",
                                                                                  rules:
                                                                                    "required",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function ({
                                                                                        errors,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      shippingAddress.city,
                                                                                                    expression:
                                                                                                      "shippingAddress.city",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "w-full",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  id:
                                                                                                    "city" +
                                                                                                    index,
                                                                                                  name: "shippingAddresses[][city]",
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value:
                                                                                                    shippingAddress.city,
                                                                                                },
                                                                                              on: {
                                                                                                input:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      shippingAddress,
                                                                                                      "city",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          errors.length
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "error",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      errors[0]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input w-full md:w-3/12 px-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  for:
                                                                                    "zipCode" +
                                                                                    index,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Postcode"
                                                                                  )
                                                                                ) +
                                                                                  " *"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "validation-provider",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "Postcode",
                                                                                  rules:
                                                                                    "required",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function ({
                                                                                        errors,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      shippingAddress.zipCode,
                                                                                                    expression:
                                                                                                      "shippingAddress.zipCode",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "w-full",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "text",
                                                                                                  id:
                                                                                                    "firstName" +
                                                                                                    index,
                                                                                                  name: "shippingAddresses[][zipCode]",
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value:
                                                                                                    shippingAddress.zipCode,
                                                                                                },
                                                                                              on: {
                                                                                                input:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      shippingAddress,
                                                                                                      "zipCode",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          errors.length
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "error",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      errors[0]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input w-full md:w-4/12 px-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  for:
                                                                                    "country" +
                                                                                    index,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Land"
                                                                                  )
                                                                                ) +
                                                                                  " *"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "validation-provider",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "Land",
                                                                                  rules:
                                                                                    "required",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function ({
                                                                                        errors,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "div",
                                                                                            {
                                                                                              staticClass:
                                                                                                "select-styling w-full",
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "select",
                                                                                                {
                                                                                                  directives:
                                                                                                    [
                                                                                                      {
                                                                                                        name: "model",
                                                                                                        rawName:
                                                                                                          "v-model",
                                                                                                        value:
                                                                                                          shippingAddress.country,
                                                                                                        expression:
                                                                                                          "shippingAddress.country",
                                                                                                      },
                                                                                                    ],
                                                                                                  attrs:
                                                                                                    {
                                                                                                      name: "shippingAddresses[][country]",
                                                                                                      id:
                                                                                                        "country" +
                                                                                                        index,
                                                                                                    },
                                                                                                  on: {
                                                                                                    change:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        var $$selectedVal =
                                                                                                          Array.prototype.filter
                                                                                                            .call(
                                                                                                              $event
                                                                                                                .target
                                                                                                                .options,
                                                                                                              function (
                                                                                                                o
                                                                                                              ) {
                                                                                                                return o.selected
                                                                                                              }
                                                                                                            )
                                                                                                            .map(
                                                                                                              function (
                                                                                                                o
                                                                                                              ) {
                                                                                                                var val =
                                                                                                                  "_value" in
                                                                                                                  o
                                                                                                                    ? o._value
                                                                                                                    : o.value
                                                                                                                return val
                                                                                                              }
                                                                                                            )
                                                                                                        _vm.$set(
                                                                                                          shippingAddress,
                                                                                                          "country",
                                                                                                          $event
                                                                                                            .target
                                                                                                            .multiple
                                                                                                            ? $$selectedVal
                                                                                                            : $$selectedVal[0]
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "option",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          value:
                                                                                                            "",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "Selecteer"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "option",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          value:
                                                                                                            "be",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "België"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "option",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          value:
                                                                                                            "nl",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "Nederland"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    " "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "option",
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          value:
                                                                                                            "anywhere",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "Andere"
                                                                                                          )
                                                                                                        )
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          errors.length
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "error",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      errors[0]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "input w-full px-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  for:
                                                                                    "qty" +
                                                                                    index,
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Aantal voor deze locatie"
                                                                                  )
                                                                                ) +
                                                                                  "\n                                                            (" +
                                                                                  _vm._s(
                                                                                    `${_vm.$t(
                                                                                      "per"
                                                                                    )} ${
                                                                                      _vm
                                                                                        .options
                                                                                        .qtyInCarton >
                                                                                      1
                                                                                        ? _vm
                                                                                            .options
                                                                                            .qtyInCarton
                                                                                        : ""
                                                                                    } ${
                                                                                      _vm
                                                                                        .options
                                                                                        .qtyInCarton >
                                                                                      1
                                                                                        ? _vm.$t(
                                                                                            "stuk(s)"
                                                                                          )
                                                                                        : _vm.$t(
                                                                                            "stuk"
                                                                                          )
                                                                                    }`
                                                                                  ) +
                                                                                  ") *"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "validation-provider",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  name: "Aantal",
                                                                                  rules:
                                                                                    "required",
                                                                                },
                                                                              scopedSlots:
                                                                                _vm._u(
                                                                                  [
                                                                                    {
                                                                                      key: "default",
                                                                                      fn: function ({
                                                                                        errors,
                                                                                      }) {
                                                                                        return [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              directives:
                                                                                                [
                                                                                                  {
                                                                                                    name: "model",
                                                                                                    rawName:
                                                                                                      "v-model",
                                                                                                    value:
                                                                                                      shippingAddress.qty,
                                                                                                    expression:
                                                                                                      "shippingAddress.qty",
                                                                                                  },
                                                                                                ],
                                                                                              staticClass:
                                                                                                "w-full",
                                                                                              attrs:
                                                                                                {
                                                                                                  type: "number",
                                                                                                  id:
                                                                                                    "qty" +
                                                                                                    index,
                                                                                                  name: "shippingAddresses[][qty]",
                                                                                                },
                                                                                              domProps:
                                                                                                {
                                                                                                  value:
                                                                                                    shippingAddress.qty,
                                                                                                },
                                                                                              on: {
                                                                                                change:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    return _vm.recalculateMultipleQtys(
                                                                                                      index,
                                                                                                      $event
                                                                                                    )
                                                                                                  },
                                                                                                input:
                                                                                                  function (
                                                                                                    $event
                                                                                                  ) {
                                                                                                    if (
                                                                                                      $event
                                                                                                        .target
                                                                                                        .composing
                                                                                                    )
                                                                                                      return
                                                                                                    _vm.$set(
                                                                                                      shippingAddress,
                                                                                                      "qty",
                                                                                                      $event
                                                                                                        .target
                                                                                                        .value
                                                                                                    )
                                                                                                  },
                                                                                              },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          errors.length
                                                                                            ? _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "error",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      errors[0]
                                                                                                    )
                                                                                                  ),
                                                                                                ]
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ]
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                                  null,
                                                                                  true
                                                                                ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "address--form__buttons",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn w-full bg-red-600 text-white border-red-600 md:w-auto",
                                                                              attrs:
                                                                                {
                                                                                  type: "submit",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.validateAddress(
                                                                                      "multiple-addresses",
                                                                                      index
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.$t(
                                                                                    "Bevestig adres"
                                                                                  )
                                                                                ) +
                                                                                  "\n                                                        "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "text-sm underline mt-4 md:mt-0 md:ml-4 inline-flex items-center",
                                                                              attrs:
                                                                                {
                                                                                  href: "#",
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    $event.preventDefault()
                                                                                    return _vm.removeShippingAddress(
                                                                                      index
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-2",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "svg",
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          xmlns:
                                                                                            "http://www.w3.org/2000/svg",
                                                                                          width:
                                                                                            "14",
                                                                                          height:
                                                                                            "16",
                                                                                        },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "g",
                                                                                        {
                                                                                          attrs:
                                                                                            {
                                                                                              fill: "none",
                                                                                              "fill-rule":
                                                                                                "evenodd",
                                                                                            },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "path",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  d: "M.25 11.368c.03.797.099 1.663.632 2.256.572.637 1.504.77 2.356.85 1.293.121 2.589.213 3.886.276.606.03 1.242.047 1.79-.216.97-.466 1.349-1.636 1.543-2.695a20.036 20.036 0 0 0-.186-8.092l-4.645-.295C4.5 3.38 1.862 2.71.842 3.147-.603 3.767.25 9.754.25 11.368",
                                                                                                  fill: "#E6CCF2",
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "g",
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  stroke:
                                                                                                    "#001E62",
                                                                                                  "stroke-linecap":
                                                                                                    "round",
                                                                                                  "stroke-linejoin":
                                                                                                    "round",
                                                                                                },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "path",
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      d: "M1.5 3.272l12.466.222M2.583 3.551l.385 8.15c.036.762-.043 1.747.5 2.285.51.506 1.292.593 2.011.633 1.325.076 5.321.561 6.398-.255 1.208-.916.824-5.944.916-8.6M5.07 3.187c-.115-.751-.188-1.637.368-2.156.318-.296.769-.393 1.199-.45A9.779 9.779 0 0 1 8.766.54c.51.045 1.049.145 1.428.49.612.56.58 1.516.5 2.342M6.268 6.373l.163 5.07M9.283 6.465c0 1.987 0 2.7-.008 4.915",
                                                                                                    },
                                                                                                }
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                "\n                                                            " +
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "Verwijder adres"
                                                                                    )
                                                                                  ) +
                                                                                  "\n                                                        "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ])
                                            }
                                          ),
                                          _vm._v(" "),
                                          !_vm.inAddressMode
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "btn w-full md:w-auto mb-8",
                                                  attrs: { href: "#" },
                                                  on: {
                                                    click: function ($event) {
                                                      $event.preventDefault()
                                                      return _vm.addShippingAddress.apply(
                                                        null,
                                                        arguments
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t(
                                                        "Voeg een adres toe"
                                                      )
                                                    )
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.delivery === "individual-addresses"
                                  ? [
                                      _c(
                                        "div",
                                        { staticClass: "address--form" },
                                        [
                                          _c("validation-observer", {
                                            ref: "individual-addresses-validation",
                                            staticClass: "individual-address",
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "default",
                                                  fn: function ({
                                                    errors,
                                                    invalid,
                                                  }) {
                                                    return [
                                                      _vm._l(
                                                        _vm.deliveryPricesInfo,
                                                        function (priceInfo) {
                                                          return _vm.deliveryPricesInfo
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "input px-2 w-full md:w-1/2 nm:w-1/3",
                                                                },
                                                                [
                                                                  _c(
                                                                    "label",
                                                                    {
                                                                      attrs: {
                                                                        for:
                                                                          "qty" +
                                                                          priceInfo
                                                                            .country
                                                                            .slug,
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "Adressen in"
                                                                          )
                                                                        ) +
                                                                          " " +
                                                                          _vm._s(
                                                                            priceInfo
                                                                              .country
                                                                              .title
                                                                          )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "validation-provider",
                                                                    {
                                                                      attrs: {
                                                                        name:
                                                                          "Aantal " +
                                                                          priceInfo
                                                                            .country
                                                                            .title,
                                                                        rules:
                                                                          "required",
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "default",
                                                                              fn: function ({
                                                                                errors,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    "input",
                                                                                    {
                                                                                      directives:
                                                                                        [
                                                                                          {
                                                                                            name: "model",
                                                                                            rawName:
                                                                                              "v-model",
                                                                                            value:
                                                                                              _vm
                                                                                                .individualQtys[
                                                                                                priceInfo
                                                                                                  .country
                                                                                                  .slug
                                                                                              ],
                                                                                            expression:
                                                                                              "individualQtys[priceInfo.country.slug]",
                                                                                          },
                                                                                        ],
                                                                                      staticClass:
                                                                                        "w-full",
                                                                                      attrs:
                                                                                        {
                                                                                          type: "number",
                                                                                          "data-country":
                                                                                            priceInfo
                                                                                              .country
                                                                                              .slug,
                                                                                          id:
                                                                                            "qty" +
                                                                                            priceInfo
                                                                                              .country
                                                                                              .slug,
                                                                                        },
                                                                                      domProps:
                                                                                        {
                                                                                          value:
                                                                                            _vm
                                                                                              .individualQtys[
                                                                                              priceInfo
                                                                                                .country
                                                                                                .slug
                                                                                            ],
                                                                                        },
                                                                                      on: {
                                                                                        change:
                                                                                          _vm.recalculateIndividualQtys,
                                                                                        input:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            if (
                                                                                              $event
                                                                                                .target
                                                                                                .composing
                                                                                            )
                                                                                              return
                                                                                            _vm.$set(
                                                                                              _vm.individualQtys,
                                                                                              priceInfo
                                                                                                .country
                                                                                                .slug,
                                                                                              $event
                                                                                                .target
                                                                                                .value
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  errors.length
                                                                                    ? _c(
                                                                                        "span",
                                                                                        {
                                                                                          staticClass:
                                                                                            "error",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            _vm._s(
                                                                                              errors[0]
                                                                                            )
                                                                                          ),
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e(),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e()
                                                        }
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "input px-2 mt-2 w-full nm:w-1/3 flex justify-end",
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn w-full bg-red-600 text-white border-red-600",
                                                              attrs: {
                                                                type: "submit",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    $event.preventDefault()
                                                                    return _vm.validateAddress(
                                                                      "individual-addresses",
                                                                      _vm.index
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                                    " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "Bevestig aantal"
                                                                    )
                                                                  ) +
                                                                  "\n                                                "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              3674542968
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "btn flex-grow mr-4 mb-8 w-full",
                                          attrs: {
                                            href: "/assets/uploads/site/Orderimport-Online.xlsx",
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("Download voorbeeld Excel")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "h5",
                                  { staticClass: "w-full text-sm mb-4" },
                                  [
                                    _c("span", { staticClass: "font-bold" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "Verzendmogelijkheden en prijzen"
                                          )
                                        )
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "relative" }, [
                                  _c("div", {
                                    staticClass:
                                      "responsive-table-wrapper__gradient",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "responsive-table-wrapper" },
                                    [
                                      _vm.deliveryPricesInfo
                                        ? _c(
                                            "div",
                                            { staticClass: "responsive-table" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "flex w-full border-b border-blue-300 py-2",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-9/12 text-xs uppercase text-blue-400",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(_vm.$t("Prijs"))
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "w-3/12 text-xs uppercase text-blue-400 text-right",
                                                    },
                                                    [
                                                      _vm.delivery !==
                                                      "individual-addresses"
                                                        ? [
                                                            _vm._v(
                                                              "\n                                                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Gratis vanaf"
                                                                  )
                                                                ) +
                                                                "\n                                                    "
                                                            ),
                                                          ]
                                                        : _vm.delivery.includes(
                                                            "pickup"
                                                          )
                                                        ? [
                                                            _vm._v(
                                                              "\n                                                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Verzendingspartner"
                                                                  )
                                                                ) +
                                                                "\n                                                    "
                                                            ),
                                                          ]
                                                        : [
                                                            _vm._v(
                                                              "\n                                                        " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "Verzendingspartner"
                                                                  )
                                                                ) +
                                                                "\n                                                    "
                                                            ),
                                                          ],
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm._l(
                                                _vm.deliveryPricesInfo,
                                                function (priceInfo) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "flex w-full border-b border-blue-300 py-2",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "w-9/12 text-sm",
                                                        },
                                                        [
                                                          _vm.delivery ===
                                                          "single-address"
                                                            ? [
                                                                _vm._v(
                                                                  "\n                                                        " +
                                                                    _vm._s(
                                                                      priceInfo.title
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                              ]
                                                            : _vm.delivery.includes(
                                                                "pickup"
                                                              )
                                                            ? [
                                                                _vm._v(
                                                                  "\n                                                        " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "Gratis"
                                                                      )
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  "\n                                                        " +
                                                                    _vm._s(
                                                                      priceInfo.salePrice >
                                                                        0
                                                                        ? _vm.currency(
                                                                            priceInfo.salePrice
                                                                          ) +
                                                                            _vm.$t(
                                                                              "/adres"
                                                                            ) +
                                                                            " (" +
                                                                            priceInfo
                                                                              .vat
                                                                              .name +
                                                                            ")"
                                                                        : _vm.$t(
                                                                            "Prijs op aanvraag"
                                                                          )
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                              ],
                                                        ],
                                                        2
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "w-3/12 text-sm text-right",
                                                        },
                                                        [
                                                          _vm.delivery ===
                                                          "single-address"
                                                            ? [
                                                                _vm._v(
                                                                  "\n                                                        " +
                                                                    _vm._s(
                                                                      priceInfo.freeDelivery >
                                                                        0
                                                                        ? _vm.currency(
                                                                            priceInfo.freeDelivery
                                                                          )
                                                                        : ""
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                              ]
                                                            : _vm.delivery ===
                                                              "multiple-addresses"
                                                            ? [
                                                                _vm._v(
                                                                  "\n                                                        " +
                                                                    _vm._s(
                                                                      priceInfo.freeDelivery >
                                                                        0
                                                                        ? _vm.currency(
                                                                            priceInfo.freeDelivery
                                                                          ) +
                                                                            _vm.$t(
                                                                              "/adres"
                                                                            )
                                                                        : ""
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  "\n                                                        " +
                                                                    _vm._s(
                                                                      priceInfo
                                                                        .deliveryPartner
                                                                        .title
                                                                    ) +
                                                                    "\n                                                    "
                                                                ),
                                                              ],
                                                        ],
                                                        2
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.chosenOptions.personalisation.deliveryTerm
                                  ? _c(
                                      "small",
                                      { staticClass: "caption mt-8" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-bold" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.chosenOptions
                                                  .personalisation.deliveryTerm
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              2
                            )
                          : _vm._e(),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.activeStep === 3,
                          expression: "activeStep === 3",
                        },
                      ],
                      ref: "step-3",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "w-full" },
                        [
                          _vm.chosenOptions.personalisation
                            ? [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "uppercase text-purple-500 text-xs mb-2",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("Personalisatie")) +
                                        ": " +
                                        _vm._s(
                                          _vm.chosenOptions.personalisation
                                            .title
                                        )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "mb-4" }, [
                                  _c("div", { staticClass: "flex" }, [
                                    _vm.chosenOptions.personalisation.packshots
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "perso-info__image w-1/4 pr-8",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "swiper-container",
                                                attrs: {
                                                  id: "personalisation-swiper",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "swiper-wrapper",
                                                  },
                                                  _vm._l(
                                                    _vm.chosenOptions
                                                      .personalisation
                                                      .packshots,
                                                    function (packshot) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "swiper-slide",
                                                        },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: packshot.thumbUrl,
                                                              alt: packshot.title,
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  0
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  staticClass:
                                                    "swiper-pagination",
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass:
                                        "item-description wysiwyg w-3/4 text-sm flex-grow mb-2",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.chosenOptions.personalisation
                                            .description
                                        ),
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _vm.chosenOptions.personalisation.cutsheet
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "underline text-sm",
                                          attrs: {
                                            href: _vm.chosenOptions
                                              .personalisation.cutsheet.url,
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("Download kapvorm"))
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.chosenOptions.delivery
                            ? [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "uppercase text-purple-500 text-xs mb-4",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.$t("Verzending")) +
                                        ": " +
                                        _vm._s(_vm.chosenOptions.delivery.label)
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "item-description wysiwyg w-full text-sm flex-grow mb-4",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.chosenOptions.delivery.description
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _vm.delivery === "single-address"
                                  ? [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "address--minimized mb-8",
                                        },
                                        [
                                          _c("p", { staticClass: "text-sm" }, [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.shippingAddress.firstName
                                                ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.shippingAddress.lastName
                                                  ) +
                                                  " " +
                                                  _vm._s(
                                                    _vm.shippingAddress
                                                      .businessName
                                                      ? "- " +
                                                          _vm.shippingAddress
                                                            .businessName
                                                      : ""
                                                  )
                                              ),
                                            ]),
                                            _c("br"),
                                            _vm._v(
                                              "\n                                            " +
                                                _vm._s(
                                                  _vm.shippingAddress.address1
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.shippingAddress.address2
                                                ) +
                                                _vm._s(
                                                  _vm.shippingAddress.address3
                                                    ? " bus " +
                                                        _vm.shippingAddress
                                                          .address3
                                                    : ""
                                                ) +
                                                ",\n                                            " +
                                                _vm._s(
                                                  _vm.shippingAddress.zipCode
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.shippingAddress.city
                                                ) +
                                                ", " +
                                                _vm._s(
                                                  _vm.shippingAddress.country
                                                )
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.delivery === "multiple-addresses"
                                  ? _vm._l(
                                      _vm.shippingAddresses,
                                      function (shippingAddress, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "address--minimized",
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "address--minimized__qty text-sm",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      shippingAddress.qty
                                                    ) +
                                                      " " +
                                                      _vm._s(_vm.$t("stuk(s)"))
                                                  ),
                                                ]
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "flex-grow text-sm",
                                              },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(
                                                      shippingAddress.firstName
                                                    ) +
                                                      " " +
                                                      _vm._s(
                                                        shippingAddress.lastName
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        shippingAddress.businessName
                                                          ? "- " +
                                                              shippingAddress.businessName
                                                          : ""
                                                      )
                                                  ),
                                                ]),
                                                _c("br"),
                                                _vm._v(
                                                  "\n                                            " +
                                                    _vm._s(
                                                      shippingAddress.address1
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      shippingAddress.address2
                                                    ) +
                                                    _vm._s(
                                                      shippingAddress.address3
                                                        ? " bus " +
                                                            shippingAddress.address3
                                                        : ""
                                                    ) +
                                                    ",\n                                            " +
                                                    _vm._s(
                                                      shippingAddress.zipCode
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      shippingAddress.city
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      shippingAddress.country
                                                    )
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.delivery === "individual-addresses"
                                  ? [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "underline text-sm mb-8 block",
                                          attrs: {
                                            href: "/assets/uploads/site/Orderimport-Online.xlsx",
                                            target: "_blank",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("Download voorbeeld Excel")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.chosenOptions.personalisation.deliveryTerm
                                  ? _c(
                                      "small",
                                      { staticClass: "caption mt-4" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "font-bold" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.chosenOptions
                                                  .personalisation.deliveryTerm
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _vm.item
          ? _c("aside", { staticClass: "panel-aside" }, [
              _c(
                "div",
                {
                  staticClass:
                    "cart__panel cart-teaser--checkout relative overflow-hidden",
                },
                [
                  _c("h4", { staticClass: "mb-6" }, [
                    _vm._v(_vm._s(_vm.$t("Overzicht"))),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "font-normal mb-4" }, [
                    _vm._v(_vm._s(_vm.item.snapshot.product.title)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "mb-6" },
                    [
                      _c("li", { staticClass: "flex justify-between mb-2" }, [
                        _c("span", { staticClass: "text-sm" }, [
                          _vm._v(
                            _vm._s(_vm.item.qty) +
                              " " +
                              _vm._s(_vm.$t("stuk(s)"))
                          ),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "text-sm" }, [
                          _vm._v(_vm._s(_vm.item.subtotalAsCurrency)),
                        ]),
                      ]),
                      _vm._v(" "),
                      _vm.chosenOptions.personalisation
                        ? _c(
                            "li",
                            { staticClass: "flex justify-between mb-2" },
                            [
                              _vm.chosenOptions.personalisation.salePrice > 0
                                ? [
                                    _c("span", { staticClass: "text-sm" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("Personalisatie")) +
                                          " (" +
                                          _vm._s(_vm.item.qty) +
                                          " x " +
                                          _vm._s(
                                            _vm.currency(
                                              _vm.chosenOptions.personalisation
                                                .salePrice
                                            )
                                          ) +
                                          ")"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-sm" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.currency(_vm.totalPersonalisation)
                                        )
                                      ),
                                    ]),
                                  ]
                                : _vm.personalisation === "P35126"
                                ? [
                                    _c("span", { staticClass: "text-sm" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("Volledig gepersonaliseerd")
                                        )
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-sm" }, [
                                      _vm._v(_vm._s(_vm.$t("Incl. in prijs"))),
                                    ]),
                                  ]
                                : [
                                    _c("span", { staticClass: "text-sm" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("Geen personalisatie"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("span", { staticClass: "text-sm" }, [
                                      _vm._v(_vm._s(_vm.$t("Gratis"))),
                                    ]),
                                  ],
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cart.totalShippingCost > 0
                        ? [
                            _c(
                              "li",
                              { staticClass: "flex justify-between mb-2" },
                              [
                                _c("span", { staticClass: "text-sm" }, [
                                  _vm._v(_vm._s(_vm.$t("Verzending"))),
                                ]),
                                _vm._v(" "),
                                _vm.cart.totalShippingCost > 0
                                  ? _c("span", { staticClass: "text-sm" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.cart.totalShippingCostAsCurrency
                                        )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.$t("Gratis"))),
                                    ]),
                              ]
                            ),
                          ]
                        : _vm.deliveryOrderLines.length
                        ? _vm._l(_vm.deliveryOrderLines, function (orderLine) {
                            return _c(
                              "li",
                              { staticClass: "flex justify-between mb-2" },
                              [
                                _c("span", { staticClass: "text-sm" }, [
                                  _vm._v(_vm._s(orderLine.label)),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-sm" }, [
                                  _vm._v(
                                    _vm._s(
                                      orderLine.free
                                        ? _vm.$t("Gratis")
                                        : _vm.currency(orderLine.price)
                                    )
                                  ),
                                ]),
                              ]
                            )
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.discounts.length
                        ? _vm._l(_vm.discounts, function (discount) {
                            return _c(
                              "li",
                              { staticClass: "flex justify-between mb-2" },
                              [
                                _c("span", { staticClass: "text-sm" }, [
                                  _vm._v(_vm._s(discount.name)),
                                ]),
                                _vm._v(" "),
                                _c("span", { staticClass: "text-sm" }, [
                                  _vm._v(_vm._s(discount.amountAsCurrency)),
                                ]),
                              ]
                            )
                          })
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "flex justify-between items-baseline mb-2" },
                    [
                      _c("small", { staticClass: "mr-4" }, [
                        _vm._v(
                          _vm._s(_vm.$t("Totaalprijs")) +
                            " (" +
                            _vm._s(_vm.$t("exclBtw")) +
                            ")"
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.cart.totalShippingCost > 0
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.cart.totalTaxablePriceAsCurrency)
                            ),
                          ])
                        : _c("span", [_vm._v(_vm._s(_vm.configurationTotal))]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "panel-buttons" }, [
                _c(
                  "a",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.activeStep > 1,
                        expression: "activeStep > 1",
                      },
                    ],
                    staticClass: "btn-prev-wrapper",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.prevStep.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c(
                      "svg",
                      {
                        staticClass: "icon-back mr-2",
                        attrs: {
                          width: "6",
                          height: "8",
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 6 8",
                          stroke: "#992BD1",
                          "stroke-width": "1.5",
                        },
                      },
                      [
                        _c("title", [_vm._v("Vorige stap")]),
                        _vm._v(" "),
                        _c("polyline", {
                          attrs: {
                            fill: "none",
                            "stroke-linejoin": "round",
                            points: "-2 11 2 15 6 11",
                            transform: "matrix(0 -1 -1 0 16 6)",
                          },
                        }),
                      ]
                    ),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.$t("Vorige stap")) +
                        "\n                    "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.activeStep < 3
                  ? _c(
                      "div",
                      {
                        staticClass: "btn-next-wrapper",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.validateForm(_vm.activeStep)
                          },
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn--lg btn--fill",
                            attrs: { href: "#" },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(_vm.$t("Volgende stap")) +
                                "\n                        "
                            ),
                          ]
                        ),
                      ]
                    )
                  : _c("div", { staticClass: "btn-next-wrapper" }, [
                      _c(
                        "a",
                        {
                          staticClass: "btn btn--lg btn--fill",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.savePersonalisation.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.$t("Bewaar personalisatie")) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]),
              ]),
            ])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c(
          "div",
          {
            staticClass:
              "loading absolute top-0 left-0 w-full h-full bg-white z-50 flex justify-center items-center",
          },
          [
            _vm._v(
              "\n        " +
                _vm._s(_vm.$t("Personalisatie aan het laden")) +
                "\n    "
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }