<template>
    <div class="configure g-px py-8 md:py-16">
        <!-- Modal close button -->
        <button class="btn-close"
            @click.prevent="$emit('close')">
            <svg viewBox="0 0 48 48"
                xmlns="http://www.w3.org/2000/svg">
                <g id="Symbols"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd">
                    <g id="icon/close">
                        <g id="Group-2">
                            <g id="Group-19"
                                fill="#FFFFFF">
                                <circle id="Oval"
                                    cx="24"
                                    cy="24"
                                    r="24"></circle>
                            </g>
                            <g id="Group"
                                transform="translate(15.000000, 15.000000)"
                                fill="#001e62">
                                <rect id="Rectangle"
                                    transform="translate(9.000000, 9.500000) scale(1, -1) rotate(45.000000) translate(-9.000000, -9.500000) "
                                    x="-3"
                                    y="9"
                                    width="24"
                                    height="1"
                                    rx="0.5"></rect>
                                <rect id="Rectangle"
                                    transform="translate(9.000000, 9.500000) scale(1, -1) rotate(-45.000000) translate(-9.000000, -9.500000) "
                                    x="-3"
                                    y="9"
                                    width="24"
                                    height="1"
                                    rx="0.5"></rect>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>
        </button>

        <div class="row-c-sm flex flex-col">
            <!-- Step navigation -->
            <div class="steps">
                <a :class="activeStep === 1 ? 'active' : ''"
                    class="step">
                    <div class="step-number">1</div>
                    <label>{{ $t('Personalisatie') }}</label>
                </a>
                <a :class="activeStep === 2 ? 'active' : ''"
                    class="step">
                    <div class="step-number">2</div>
                    <label>{{ $t('Verzending') }}</label>
                </a>
                <a :class="activeStep === 3 ? 'active' : ''"
                    class="step">
                    <div class="step-number">3</div>
                    <label>{{ $t('Samenvatting') }}</label>
                </a>
            </div>

            <div class="panel-wrapper">
                <main class="panel-main">
                    <h3 class="mb-12">{{ stepTitle }}</h3>
                    <template v-if="item">
                        <div class="item">
                            <div class="item-thumbnail">
                                <img v-if="item.productThumbnail"
                                    :src="item.productThumbnail"
                                    :alt="item.snapshot.product.title">
                                <img v-else
                                    src="/assets/svg/no-picture.svg"
                                    :alt="item.snapshot.product.title">
                            </div>
                            <div class="item-info">
                                <div class="nm:mr-4">
                                    <h4 class="mb-2">{{ item.snapshot.product.title }}</h4>
                                    <p class="mb-2">{{ item.snapshot.product.description }}</p>
                                    <p class="text-sm">
                                        <template v-if="item.onSale">
                                            {{ item.qty }} {{ $t('stuk(s)') }} - <span class="text-sm line-through">{{ item.priceAsCurrency }}</span> {{ item.salePriceAsCurrency }} {{ $t('per stuk') }}
                                        </template>
                                        <template v-else>
                                            {{ item.qty }} {{ $t('stuk(s)') }} - {{ item.priceAsCurrency }} {{ $t('per stuk') }}
                                        </template>
                                    </p>
                                    <p v-if="chosenOptions.personalisation" class="text-sm">
                                        <b>{{ $t('Personalisatie') }}:</b>
                                        {{ chosenOptions.personalisation.title }}
                                        {{ (chosenOptions.personalisation.salePrice > 0) ? "- " + currency(chosenOptions.personalisation.salePrice) + " " + $t('per stuk') : "" }}
                                    </p>
                                    <p v-if="chosenOptions.delivery" class="text-sm"><b>{{ $t('Verzending') }}:</b> {{ this.chosenOptions.delivery.label }}</p>
                                </div>
                                <span class="font-normal text-2xl mb-4 nm:mb-0">{{ item.subtotalAsCurrency }}</span>
                            </div>
                        </div>

                        <div v-show="activeStep === 1"
                            ref="step-1">
                            <div class="panel-step form">
                                <div class="panel__field w-full nm:w-2/5 mb-4 nm:mb-0" :class="Object.keys(options.personalisation).length > 1 ? 'nm:w-2/5 nm:pr-8' : 'nm:w-full'">
                                    <label for="qty-step-1">{{ $t('Aantal') }} ({{ `${$t('per')} ${options.qtyInCarton > 1 ? options.qtyInCarton : ''} ${options.qtyInCarton > 1 ? $t('stuk(s)') : $t('stuk')}` }})</label>
                                    <input id="qty-step-1"
                                        type="number"
                                        :value="item.qty"
                                        @change="qtyChange(index, $event)">
                                </div>
                                <div v-if="Object.keys(options.personalisation).length > 1" class="panel__field w-full nm:w-3/5">
                                    <label for="personalisation">{{ $t('Personalisatie') }}</label>
                                    <div class="select-styling">
                                        <select id="personalisation"
                                            v-model="personalisation"
                                            class="overflow-hidden"
                                            @change="showPersonalisationInfo">
                                            <option value="">{{ $t('Selecteer een personalisatie') }}</option>
                                            <option v-for="(personalise, key) in options.personalisation"
                                                :value="key">{{ personalise.title }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div v-if="chosenOptions.personalisation"
                                    class="mt-4 nm:mt-8 w-full">
                                    <div class="flex"
                                        v-if="chosenOptions.personalisation.description || chosenOptions.personalisation.packshots">
                                        <div class="w-1/4 pr-8"
                                            v-if="chosenOptions.personalisation.packshots">
                                            <div class="swiper-container"
                                                id="personalisation-swiper">
                                                <div class="swiper-wrapper">
                                                    <div v-for="packshot in chosenOptions.personalisation.packshots"
                                                        class="swiper-slide">
                                                        <img :src="packshot.thumbUrl"
                                                            :alt="packshot.title">
                                                    </div>
                                                </div>
                                                <div class="swiper-pagination"></div>
                                            </div>
                                        </div>
                                        <div class="item-description wysiwyg"
                                            v-html="chosenOptions.personalisation.description"></div>
                                    </div>
                                    <a v-if="chosenOptions.personalisation.cutsheet"
                                        :href="chosenOptions.personalisation.cutsheet.url"
                                        target="_blank"
                                        class="btn flex-grow mr-4 mb-6 w-full">{{ $t('Download kapvorm') }}</a>
                                    <div class="mb-4">
                                        <label class="block uppercase text-purple-500 text-xs mb-2">{{ $t('Gewenste leveringsdatum') }}</label>
                                        <datepicker v-model="desiredDeliveryDateSelected"
                                            :format="desiredDeliveryDate.format"
                                            :disabled-dates="{ to: calcDesiredDeliveryDateStart, days: [6, 0] }"
                                            :language="locale"
                                            :monday-first="true"
                                            :full-month-name="true"></datepicker>
                                    </div>
                                    <small v-if="chosenOptions.personalisation.deliveryTerm"
                                        class="caption mt-4"><span class="font-bold">{{ chosenOptions.personalisation.deliveryTerm }}</span></small>
                                </div>
                            </div>
                        </div>

                        <div v-show="activeStep === 2"
                            ref="step-2">
                            <div class="panel-step form">
                                <div class="panel__field w-full nm:w-2/5 mb-4 nm:mb-0 nm:pr-8">
                                    <label for="qty-step-2">{{ $t('Aantal') }} ({{ `${$t('per')} ${options.qtyInCarton > 1 ? options.qtyInCarton : ''} ${options.qtyInCarton > 1 ? $t('stuks') : $t('stuk')}` }})</label>
                                    <input id="qty-step-2"
                                        type="number"
                                        :value="item.qty"
                                        @change="qtyChange(index, $event)">
                                </div>
                                <div class="panel__field w-full nm:w-3/5">
                                    <label for="delivery">{{ $t('Verzending') }}</label>
                                    <div class="select-styling">
                                        <select v-model="delivery"
                                            id="delivery"
                                            class="overflow-hidden"
                                            @change="showDeliveryInfo">
                                            <option value="">{{ $t('Selecteer een verzending') }}</option>
                                            <option v-for="(option, id) in deliveryTypes"
                                                :value="id">{{ option.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <div v-if="chosenOptions.delivery" class="mt-4 nm:mt-8 w-full">
                                    <div class="item-description wysiwyg" v-html="chosenOptions.delivery.description"></div>

                                    <template v-if="delivery === 'single-address'">
                                        <validation-observer ref="single-address-validation"
                                            v-slot="{ errors, invalid }">
                                            <div v-if="shippingAddress.minimized"
                                                class="address--minimized flex w-full justify-between items-center mb-8">
                                                <p><b>{{ shippingAddress.firstName }} {{ shippingAddress.lastName }} {{ (shippingAddress.businessName) ? "- " + shippingAddress.businessName : "" }}</b><br>
                                                    {{ shippingAddress.address1 }} {{ shippingAddress.address2 }}{{ (shippingAddress.address3) ? " bus " + shippingAddress.address3 : "" }},
                                                    {{ shippingAddress.zipCode }} {{ shippingAddress.city }}, {{ shippingAddress.country }}</p>
                                                <button type="submit"
                                                    class="btn mt-4 md:ml-4 md:mt-0 md:self-center"
                                                    @click="showAddress('single-address')"> {{ $t('Aanpassen') }}
                                                </button>
                                            </div>
                                            <div v-else
                                                class="address--form flex flex-wrap -mx-2 mb-8">
                                                <div class="input w-full md:w-1/2 px-2">
                                                    <label for="firstName">{{ $t('Voornaam') }} *</label>
                                                    <validation-provider name="Voornaam"
                                                        rules="required"
                                                        v-slot="{ errors }">
                                                        <input type="text"
                                                            id="firstName"
                                                            class="w-full"
                                                            v-model="shippingAddress.firstName">
                                                        <span v-if="errors.length"
                                                            class="error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                                <div class="input w-full md:w-1/2 px-2">
                                                    <label for="lastName">{{ $t('Achternaam') }} *</label>
                                                    <validation-provider name="Achternaam"
                                                        rules="required"
                                                        v-slot="{ errors }">
                                                        <input type="text"
                                                            id="lastName"
                                                            class="w-full"
                                                            v-model="shippingAddress.lastName">
                                                        <span v-if="errors.length"
                                                            class="error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                                <div class="input w-full px-2">
                                                    <label for="businessName">{{ $t('Bedrijf') }}</label>
                                                    <validation-provider name="Bedrijf"
                                                        rules=""
                                                        v-slot="{ errors }">
                                                        <input type="text"
                                                            id="businessName"
                                                            class="w-full"
                                                            v-model="shippingAddress.businessName">
                                                        <span v-if="errors.length"
                                                            class="error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                                <div class="input w-full md:w-8/12 px-2">
                                                    <label for="address1">{{ $t('Straat') }} *</label>
                                                    <validation-provider name="Straat"
                                                        rules="required"
                                                        v-slot="{ errors }">
                                                        <input type="text"
                                                            id="address1"
                                                            class="w-full"
                                                            v-model="shippingAddress.address1">
                                                        <span v-if="errors.length"
                                                            class="error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                                <div class="input w-1/2 md:w-2/12 px-2">
                                                    <label for="address2">{{ $t('Nr.') }} *</label>
                                                    <validation-provider name="Nr."
                                                        rules="required"
                                                        v-slot="{ errors }">
                                                        <input type="text"
                                                            id="address2"
                                                            class="w-full"
                                                            v-model="shippingAddress.address2">
                                                        <span v-if="errors.length"
                                                            class="error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                                <div class="input w-1/2 md:w-2/12 px-2">
                                                    <label for="address3">{{ $t('Bus') }}</label>
                                                    <validation-provider name="Bus"
                                                        rules=""
                                                        v-slot="{ errors }">
                                                        <input type="text"
                                                            id="address3"
                                                            class="w-full"
                                                            v-model="shippingAddress.address3">
                                                        <span v-if="errors.length"
                                                            class="error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                                <div class="input w-full md:w-5/12 px-2">
                                                    <label for="city">{{ $t('Stad') }} *</label>
                                                    <validation-provider name="Stad"
                                                        rules="required"
                                                        v-slot="{ errors }">
                                                        <input type="text"
                                                            id="city"
                                                            class="w-full"
                                                            v-model="shippingAddress.city">
                                                        <span v-if="errors.length"
                                                            class="error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                                <div class="input w-full md:w-3/12 px-2">
                                                    <label for="zipCode">{{ $t('Postcode') }} *</label>
                                                    <validation-provider name="Postcode"
                                                        rules="required"
                                                        v-slot="{ errors }">
                                                        <input type="text"
                                                            id="zipCode"
                                                            class="w-full"
                                                            v-model="shippingAddress.zipCode">
                                                        <span v-if="errors.length"
                                                            class="error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                                <div class="input w-full md:w-4/12 px-2">
                                                    <label for="country">{{ $t('Land') }} *</label>
                                                    <validation-provider name="Land"
                                                        rules="required"
                                                        v-slot="{ errors }">
                                                        <div class="select-styling w-full">
                                                            <select v-model="shippingAddress.country"
                                                                id="country">
                                                                <option value="">{{ $t('Selecteer') }}</option>
                                                                <option value="be">{{ $t('België') }}</option>
                                                                <option value="nl">{{ $t('Nederland') }}</option>
                                                                <option value="anywhere">{{ $t('Andere') }}</option>
                                                            </select>
                                                        </div>
                                                        <span v-if="errors.length"
                                                            class="error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                                <div class="w-full flex justify-end mt-4 px-2">
                                                    <button type="submit"
                                                        class="btn bg-red-600 text-white border-red-600 w-full md:w-auto"
                                                        @click.prevent="validateAddress('single-address', 0)">{{ $t("Bevestig adres") }}
                                                    </button>
                                                </div>
                                            </div>
                                        </validation-observer>
                                    </template>

                                    <template v-if="delivery === 'multiple-addresses'">
                                        <div class="shipping-addresses-wrapper">
                                            <div v-for="(shippingAddress, index) in shippingAddresses"
                                                :key="index">
                                                <div v-if="shippingAddress.minimized"
                                                    class="address--minimized">
                                                    <div class="address--minimized__qty text-sm">{{ shippingAddress.qty }} {{ shippingAddress.qty > 1 ? $t('stuk(s)') : $t('stuk') }}</div>
                                                    <p class="flex-grow text-sm">
                                                        <b>{{ shippingAddress.firstName }} {{ shippingAddress.lastName }} {{ (shippingAddress.businessName) ? "- " + shippingAddress.businessName : "" }}</b><br>
                                                        {{ shippingAddress.address1 }} {{ shippingAddress.address2 }}{{ (shippingAddress.address3) ? " bus " + shippingAddress.address3 : "" }},
                                                        {{ shippingAddress.zipCode }} {{ shippingAddress.city }}, {{ shippingAddress.country }}
                                                    </p>
                                                    <button type="submit"
                                                        class="btn mt-4 md:ml-4 md:mt-0 md:self-center"
                                                        @click="showAddress('multiple-addresses', index)">{{ $t('Aanpassen') }}
                                                    </button>
                                                </div>
                                                <div v-else
                                                    class="address--form">
                                                    <validation-observer :ref="'multiple-addresses-' + index + '-validation'"
                                                        v-slot="{ errors, invalid }"
                                                        class="flex flex-wrap -mx-2 mb-8">
                                                        <div class="input w-full md:w-1/2 px-2">
                                                            <label :for="'firstName' + index">{{ $t('Voornaam') }} *</label>
                                                            <validation-provider name="Voornaam"
                                                                rules="required"
                                                                v-slot="{ errors }">
                                                                <input type="text"
                                                                    :id="'firstName' + index"
                                                                    class="w-full"
                                                                    name="shippingAddresses[][firstName]"
                                                                    v-model="shippingAddress.firstName">
                                                                <span v-if="errors.length"
                                                                    class="error">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                        <div class="input w-full md:w-1/2 px-2">
                                                            <label :for="'lastName' + index">{{ $t('Achternaam') }} *</label>
                                                            <validation-provider name="Achternaam"
                                                                rules="required"
                                                                v-slot="{ errors }">
                                                                <input type="text"
                                                                    :id="'lastName' + index"
                                                                    class="w-full"
                                                                    name="shippingAddresses[][lastName]"
                                                                    v-model="shippingAddress.lastName">
                                                                <span v-if="errors.length"
                                                                    class="error">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                        <div class="input w-full px-2">
                                                            <label :for="'businessName' + index">{{ $t('Bedrijf') }}</label>
                                                            <validation-provider name="Bedrijf"
                                                                rules=""
                                                                v-slot="{ errors }">
                                                                <input type="text"
                                                                    :id="'businessName' + index"
                                                                    class="w-full"
                                                                    name="shippingAddresses[][businessName]"
                                                                    v-model="shippingAddress.businessName">
                                                                <span v-if="errors.length"
                                                                    class="error">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                        <div class="input w-full md:w-6/12 px-2">
                                                            <label :for="'address1' + index">{{ $t('Straat') }} *</label>
                                                            <validation-provider name="Straat"
                                                                rules="required"
                                                                v-slot="{ errors }">
                                                                <input type="text"
                                                                    :id="'address1' + index"
                                                                    class="w-full"
                                                                    name="shippingAddresses[][address1]"
                                                                    v-model="shippingAddress.address1">
                                                                <span v-if="errors.length"
                                                                    class="error">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                        <div class="input w-1/2 md:w-3/12 px-2">
                                                            <label :for="'address2' + index">{{ $t('Nr.') }} *</label>
                                                            <validation-provider name="Nr."
                                                                rules="required"
                                                                v-slot="{ errors }">
                                                                <input type="text"
                                                                    :id="'address2' + index"
                                                                    class="w-full"
                                                                    name="shippingAddresses[][address2]"
                                                                    v-model="shippingAddress.address2">
                                                                <span v-if="errors.length"
                                                                    class="error">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                        <div class="input w-1/2 md:w-3/12 px-2">
                                                            <label :for="'address3' + index">{{ $t('Bus') }}</label>
                                                            <validation-provider name="Bus"
                                                                rules=""
                                                                v-slot="{ errors }">
                                                                <input type="text"
                                                                    :id="'address3' + index"
                                                                    class="w-full"
                                                                    name="shippingAddresses[][address3]"
                                                                    v-model="shippingAddress.address3">
                                                                <span v-if="errors.length"
                                                                    class="error">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                        <div class="input w-full md:w-5/12 px-2">
                                                            <label :for="'city' + index">{{ $t('Stad') }} *</label>
                                                            <validation-provider name="Voornaam"
                                                                rules="required"
                                                                v-slot="{ errors }">
                                                                <input type="text"
                                                                    :id="'city' + index"
                                                                    class="w-full"
                                                                    name="shippingAddresses[][city]"
                                                                    v-model="shippingAddress.city">
                                                                <span v-if="errors.length"
                                                                    class="error">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                        <div class="input w-full md:w-3/12 px-2">
                                                            <label :for="'zipCode' + index">{{ $t('Postcode') }} *</label>
                                                            <validation-provider name="Postcode"
                                                                rules="required"
                                                                v-slot="{ errors }">
                                                                <input type="text"
                                                                    :id="'firstName' + index"
                                                                    class="w-full"
                                                                    name="shippingAddresses[][zipCode]"
                                                                    v-model="shippingAddress.zipCode">
                                                                <span v-if="errors.length"
                                                                    class="error">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                        <div class="input w-full md:w-4/12 px-2">
                                                            <label :for="'country' + index">{{ $t('Land') }} *</label>
                                                            <validation-provider name="Land"
                                                                rules="required"
                                                                v-slot="{ errors }">
                                                                <div class="select-styling w-full">
                                                                    <select v-model="shippingAddress.country"
                                                                        name="shippingAddresses[][country]"
                                                                        :id="'country' + index">
                                                                        <option value="">{{ $t('Selecteer') }}</option>
                                                                        <option value="be">{{ $t('België') }}</option>
                                                                        <option value="nl">{{ $t('Nederland') }}</option>
                                                                        <option value="anywhere">{{ $t('Andere') }}</option>
                                                                    </select>
                                                                </div>
                                                                <span v-if="errors.length"
                                                                    class="error">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>
                                                        <div class="input w-full px-2">
                                                            <label :for="'qty' + index">{{ $t('Aantal voor deze locatie') }}
                                                                ({{ `${$t('per')} ${options.qtyInCarton > 1 ? options.qtyInCarton : ''} ${options.qtyInCarton > 1 ? $t('stuk(s)') : $t('stuk')}` }}) *</label>
                                                            <validation-provider name="Aantal"
                                                                rules="required"
                                                                v-slot="{ errors }">
                                                                <input type="number"
                                                                    :id="'qty' + index"
                                                                    class="w-full"
                                                                    name="shippingAddresses[][qty]"
                                                                    v-model="shippingAddress.qty"
                                                                    @change="recalculateMultipleQtys(index, $event)">
                                                                <span v-if="errors.length"
                                                                    class="error">{{ errors[0] }}</span>
                                                            </validation-provider>
                                                        </div>

                                                        <div class="address--form__buttons">
                                                            <button type="submit"
                                                                class="btn w-full bg-red-600 text-white border-red-600 md:w-auto"
                                                                @click.prevent="validateAddress('multiple-addresses', index)">{{ $t('Bevestig adres') }}
                                                            </button>
                                                            <a href="#"
                                                                class="text-sm underline mt-4 md:mt-0 md:ml-4 inline-flex items-center"
                                                                @click.prevent="removeShippingAddress(index)">
                                                                <span class="mr-2">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        width="14"
                                                                        height="16">
                                                                        <g fill="none"
                                                                            fill-rule="evenodd">
                                                                                <path
                                                                                    d="M.25 11.368c.03.797.099 1.663.632 2.256.572.637 1.504.77 2.356.85 1.293.121 2.589.213 3.886.276.606.03 1.242.047 1.79-.216.97-.466 1.349-1.636 1.543-2.695a20.036 20.036 0 0 0-.186-8.092l-4.645-.295C4.5 3.38 1.862 2.71.842 3.147-.603 3.767.25 9.754.25 11.368"
                                                                                    fill="#E6CCF2"></path>
                                                                                <g stroke="#001E62"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round">
                                                                                        <path
                                                                                            d="M1.5 3.272l12.466.222M2.583 3.551l.385 8.15c.036.762-.043 1.747.5 2.285.51.506 1.292.593 2.011.633 1.325.076 5.321.561 6.398-.255 1.208-.916.824-5.944.916-8.6M5.07 3.187c-.115-.751-.188-1.637.368-2.156.318-.296.769-.393 1.199-.45A9.779 9.779 0 0 1 8.766.54c.51.045 1.049.145 1.428.49.612.56.58 1.516.5 2.342M6.268 6.373l.163 5.07M9.283 6.465c0 1.987 0 2.7-.008 4.915"></path>
                                                                                </g>
                                                                        </g>
                                                                    </svg>
                                                                </span>
                                                                {{ $t('Verwijder adres') }}
                                                            </a>
                                                        </div>
                                                    </validation-observer>
                                                </div>
                                            </div>
                                            <a v-if="!inAddressMode"
                                                href="#"
                                                class="btn w-full md:w-auto mb-8"
                                                @click.prevent="addShippingAddress">{{ $t('Voeg een adres toe') }}</a>
                                        </div>
                                    </template>

                                    <template v-if="delivery === 'individual-addresses'">
                                        <div class="address--form">
                                            <validation-observer :ref="'individual-addresses-validation'"
                                                v-slot="{ errors, invalid }"
                                                class="individual-address">
                                                <div v-if="deliveryPricesInfo"
                                                    v-for="priceInfo in deliveryPricesInfo"
                                                    class="input px-2 w-full md:w-1/2 nm:w-1/3">
                                                    <label :for="'qty' + priceInfo.country.slug">{{ $t("Adressen in") }} {{ priceInfo.country.title }}</label>
                                                    <validation-provider :name="'Aantal ' + priceInfo.country.title"
                                                        rules="required"
                                                        v-slot="{ errors }">
                                                        <input type="number"
                                                            :data-country="priceInfo.country.slug"
                                                            :id="'qty' + priceInfo.country.slug"
                                                            class="w-full"
                                                            @change="recalculateIndividualQtys"
                                                            v-model="individualQtys[priceInfo.country.slug]">
                                                        <span v-if="errors.length"
                                                            class="error">{{ errors[0] }}</span>
                                                    </validation-provider>
                                                </div>
                                                <div class="input px-2 mt-2 w-full nm:w-1/3 flex justify-end">
                                                    <button type="submit"
                                                        class="btn w-full bg-red-600 text-white border-red-600"
                                                        @click.prevent="validateAddress('individual-addresses', index)">
                                                        {{ $t("Bevestig aantal") }}
                                                    </button>
                                                </div>
                                            </validation-observer>
                                        </div>
                                        <a href="/assets/uploads/site/Orderimport-Online.xlsx"
                                            target="_blank"
                                            class="btn flex-grow mr-4 mb-8 w-full">{{ $t("Download voorbeeld Excel") }}</a>
                                    </template>

                                    <h5 class="w-full text-sm mb-4"><span class="font-bold">{{ $t("Verzendmogelijkheden en prijzen") }}</span></h5>
                                    <div class="relative">
                                        <div class="responsive-table-wrapper__gradient"></div>
                                        <div class="responsive-table-wrapper">
                                            <div v-if="deliveryPricesInfo"
                                                class="responsive-table">
                                                <div class="flex w-full border-b border-blue-300 py-2">
                                                    <div class="w-9/12 text-xs uppercase text-blue-400">{{ $t("Prijs") }}</div>
                                                    <div class="w-3/12 text-xs uppercase text-blue-400 text-right">
                                                        <template v-if="delivery !== 'individual-addresses'">
                                                            {{ $t("Gratis vanaf") }}
                                                        </template>
                                                        <template v-else-if="delivery.includes('pickup')">
                                                            {{ $t('Verzendingspartner') }}
                                                        </template>
                                                        <template v-else>
                                                            {{ $t("Verzendingspartner") }}
                                                        </template>
                                                    </div>
                                                </div>
                                                <div v-for="priceInfo in deliveryPricesInfo" class="flex w-full border-b border-blue-300 py-2">
                                                    <div class="w-9/12 text-sm">
                                                        <template v-if="delivery === 'single-address'">
                                                            {{ priceInfo.title }}
                                                        </template>
                                                        <template v-else-if="delivery.includes('pickup')">
                                                            {{ $t('Gratis') }}
                                                        </template>
                                                        <template v-else>
                                                            {{ (priceInfo.salePrice > 0) ? currency(priceInfo.salePrice) + $t("/adres") + " (" + priceInfo.vat.name + ")" : $t('Prijs op aanvraag') }}
                                                        </template>
                                                    </div>
                                                    <div class="w-3/12 text-sm text-right">
                                                        <template v-if="delivery === 'single-address'">
                                                            {{ (priceInfo.freeDelivery > 0) ? currency(priceInfo.freeDelivery) : '' }}
                                                        </template>
                                                        <template v-else-if="delivery === 'multiple-addresses'">
                                                            {{ (priceInfo.freeDelivery > 0) ? currency(priceInfo.freeDelivery) + $t("/adres") : '' }}
                                                        </template>
                                                        <template v-else>
                                                            {{ priceInfo.deliveryPartner.title }}
                                                        </template>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <small v-if="chosenOptions.personalisation.deliveryTerm"
                                        class="caption mt-8"><span class="font-bold">{{ chosenOptions.personalisation.deliveryTerm }}</span></small>
                                </div>
                            </div>
                        </div>

                        <div v-show="activeStep === 3"
                            ref="step-3">
                            <div class="w-full">
                                <template v-if="chosenOptions.personalisation">
                                    <p class="uppercase text-purple-500 text-xs mb-2">{{ $t('Personalisatie') }}: {{ chosenOptions.personalisation.title }}</p>
                                    <div class="mb-4">
                                        <div class="flex">
                                            <div v-if="chosenOptions.personalisation.packshots"
                                                class="perso-info__image w-1/4 pr-8">
                                                <div class="swiper-container"
                                                    id="personalisation-swiper">
                                                    <div class="swiper-wrapper">
                                                        <div v-for="packshot in chosenOptions.personalisation.packshots"
                                                            class="swiper-slide">
                                                            <img :src="packshot.thumbUrl"
                                                                :alt="packshot.title">
                                                        </div>
                                                    </div>
                                                    <div class="swiper-pagination"></div>
                                                </div>
                                            </div>
                                            <div class="item-description wysiwyg w-3/4 text-sm flex-grow mb-2"
                                                v-html="chosenOptions.personalisation.description"></div>
                                        </div>
                                        <a v-if="chosenOptions.personalisation.cutsheet"
                                            :href="chosenOptions.personalisation.cutsheet.url"
                                            target="_blank"
                                            class="underline text-sm">{{ $t("Download kapvorm") }}</a>
                                    </div>
                                </template>
                                <template v-if="chosenOptions.delivery">
                                    <p class="uppercase text-purple-500 text-xs mb-4">{{ $t('Verzending') }}: {{ chosenOptions.delivery.label }}</p>
                                    <div class="item-description wysiwyg w-full text-sm flex-grow mb-4"
                                        v-html="chosenOptions.delivery.description"></div>
                                    <template v-if="delivery === 'single-address'">
                                        <div class="address--minimized mb-8">
                                            <p class="text-sm"><b>{{ shippingAddress.firstName }} {{ shippingAddress.lastName }} {{ (shippingAddress.businessName) ? "- " + shippingAddress.businessName : "" }}</b><br>
                                                {{ shippingAddress.address1 }} {{ shippingAddress.address2 }}{{ (shippingAddress.address3) ? " bus " + shippingAddress.address3 : "" }},
                                                {{ shippingAddress.zipCode }} {{ shippingAddress.city }}, {{ shippingAddress.country }}</p>
                                        </div>
                                    </template>
                                    <template v-if="delivery === 'multiple-addresses'">
                                        <div v-for="(shippingAddress, index) in shippingAddresses"
                                            :key="index"
                                            class="address--minimized">
                                            <div>
                                                <div class="address--minimized__qty text-sm">{{ shippingAddress.qty }} {{ $t('stuk(s)') }}</div>
                                            </div>
                                            <p class="flex-grow text-sm"><b>{{ shippingAddress.firstName }} {{ shippingAddress.lastName }} {{ (shippingAddress.businessName) ? "- " + shippingAddress.businessName : "" }}</b><br>
                                                {{ shippingAddress.address1 }} {{ shippingAddress.address2 }}{{ (shippingAddress.address3) ? " bus " + shippingAddress.address3 : "" }},
                                                {{ shippingAddress.zipCode }} {{ shippingAddress.city }}, {{ shippingAddress.country }}</p>
                                        </div>
                                    </template>
                                    <template v-if="delivery === 'individual-addresses'">
                                        <a href="/assets/uploads/site/Orderimport-Online.xlsx"
                                            target="_blank"
                                            class="underline text-sm mb-8 block">{{ $t("Download voorbeeld Excel") }}</a>
                                    </template>
                                    <small v-if="chosenOptions.personalisation.deliveryTerm"
                                        class="caption mt-4"><span class="font-bold">{{ chosenOptions.personalisation.deliveryTerm }}</span></small>
                                </template>
                            </div>
                        </div>
                    </template>
                </main>

                <aside v-if="item"
                    class="panel-aside">
                    <div class="cart__panel cart-teaser--checkout relative overflow-hidden">
                        <h4 class="mb-6">{{ $t("Overzicht") }}</h4>
                        <p class="font-normal mb-4">{{ item.snapshot.product.title }}</p>

                        <ul class="mb-6">
                            <li class="flex justify-between mb-2">
                                <span class="text-sm">{{ item.qty }} {{ $t('stuk(s)') }}</span>
                                <span class="text-sm">{{ item.subtotalAsCurrency }}</span>
                            </li>
                            <li v-if="chosenOptions.personalisation"
                                class="flex justify-between mb-2">
                                <template v-if="chosenOptions.personalisation.salePrice > 0">
                                    <span class="text-sm">{{ $t('Personalisatie') }} ({{ item.qty }} x {{ currency(chosenOptions.personalisation.salePrice) }})</span>
                                    <span class="text-sm">{{ currency(totalPersonalisation) }}</span>
                                </template>
                                <template v-else-if="personalisation === 'P35126'">
                                    <span class="text-sm">{{ $t('Volledig gepersonaliseerd') }}</span>
                                    <span class="text-sm">{{ $t('Incl. in prijs') }}</span>
                                </template>
                                <template v-else>
                                    <span class="text-sm">{{ $t('Geen personalisatie') }}</span>
                                    <span class="text-sm">{{ $t('Gratis') }}</span>
                                </template>
                            </li>
                            <template v-if="cart.totalShippingCost > 0">
                                <li class="flex justify-between mb-2">
                                    <span class="text-sm">{{ $t("Verzending") }}</span>
                                    <span class="text-sm" v-if="cart.totalShippingCost > 0">{{ cart.totalShippingCostAsCurrency }}</span>
                                    <span v-else>{{ $t("Gratis") }}</span>
                                </li>
                            </template>
                            <template v-else-if="deliveryOrderLines.length">
                                <li v-for="orderLine in deliveryOrderLines" class="flex justify-between mb-2">
                                    <span class="text-sm">{{ orderLine.label }}</span>
                                    <span class="text-sm">{{ (orderLine.free) ? $t('Gratis') : currency(orderLine.price) }}</span>
                                </li>
                            </template>
                            <template v-if="discounts.length">
                                <li v-for="discount in discounts"
                                    class="flex justify-between mb-2">
                                    <span class="text-sm">{{ discount.name }}</span>
                                    <span class="text-sm">{{ discount.amountAsCurrency }}</span>
                                </li>
                            </template>
                        </ul>

                        <hr>

                        <p class="flex justify-between items-baseline mb-2">
                            <small class="mr-4">{{ $t("Totaalprijs") }} ({{ $t("exclBtw") }})</small>
                            <span v-if="cart.totalShippingCost > 0">{{ cart.totalTaxablePriceAsCurrency }}</span>
                            <span v-else>{{ configurationTotal }}</span>
                        </p>
                    </div>

                    <div class="panel-buttons">
                        <a v-show="activeStep > 1"
                            @click.prevent="prevStep"
                            href="#"
                            class="btn-prev-wrapper">
                            <svg width="6"
                                height="8"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 6 8"
                                stroke="#992BD1"
                                stroke-width="1.5"
                                class="icon-back mr-2"><title>Vorige stap</title>
                                <polyline fill="none"
                                    stroke-linejoin="round"
                                    points="-2 11 2 15 6 11"
                                    transform="matrix(0 -1 -1 0 16 6)"></polyline>
                            </svg>
                            {{ $t('Vorige stap') }}
                        </a>
                        <div v-if="activeStep < 3"
                            @click.prevent="validateForm(activeStep)"
                            class="btn-next-wrapper">
                            <a href="#"
                                class="btn btn--lg btn--fill">
                                {{ $t('Volgende stap') }}
                            </a>
                        </div>
                        <div v-else
                            class="btn-next-wrapper">
                            <a href="#"
                                class="btn btn--lg btn--fill"
                                @click.prevent="savePersonalisation">
                                {{ $t('Bewaar personalisatie') }}
                            </a>
                        </div>
                    </div>

                </aside>
            </div>
        </div>

        <div v-if="loading"
            class="loading absolute top-0 left-0 w-full h-full bg-white z-50 flex justify-center items-center">
            {{ $t("Personalisatie aan het laden") }}
        </div>

    </div>
</template>

<script>

import Vue from 'vue'
import axios from 'axios'
import {EventBus} from "../js/event-bus"
import VModal from 'vue-js-modal'
import Swiper from "swiper"
import Datepicker from 'vuejs-datepicker'
import {en, fr, nl} from 'vuejs-datepicker/dist/locale'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import isEqual from 'lodash.isequal'
import {mapState} from "vuex"

export default {

    name: "Configure",

    components: {
        VModal,
        ValidationProvider,
        ValidationObserver,
        Datepicker
    },

    props: {
        'index': {
            type: Number,
            default: 0
        },
        'alertHandlingByPartner': {
            type: String,
            default: ''
        },
        'productDataLayer': {
            type: Object,
            default: {}
        }
    },

    data() {
        return {
            item: false,
            stepTitles: {
                'step1': 'Personalisatie',
                'step2': 'Verzending',
                'step3': 'Samenvatting'
            },
            activeStep: 1,
            personalisation: '',
            delivery: '',
            chosenOptions: {
                'personalisation': null,
                'delivery': null,
            },
            options: {},
            loading: true,
            totalPersonalisation: 0,
            deliveryOrderLines: [],
            deliveryTypes: {},
            deliveryPricesInfo: [],
            shippingAddress: {
                firstName: '',
                lastName: '',
                address1: '',
                address2: '',
                address3: '',
                zipCode: '',
                city: '',
                country: '',
                businessName: '',
                qty: 1,
                minimized: false
            },
            shippingAddresses: [],
            inAddressMode: false,
            individualQtys: {
                be: 0,
                nl: 0
            },
            validStep2: false,
            desiredDeliveryDate: {
                date: null,
                format: 'dd MMMM yyyy'
            },
            nl: nl,
            en: en,
            fr: fr,
            messages: {}
        }
    },

    computed: {

        ...mapState({
            cartCount: state => state.cart.cartCount,
            cart: state => state.cart.commerceCart,
            showTax: state => state.cart.commerceCart.totalTax > 0,
            showShipping: state => state.cart.commerceCart.totalShippingCost > 0,
            showPromo: state => state.cart.commerceCart.totalDiscount < 0,
            isCalculating: state => state.cart.calculating,
            couponActive: state => state.cart.couponActive,
        }),

        configurationTotal: function () {
            // Calculate total discount
            let totalDiscount = 0
            this.discounts.forEach(object => {
                totalDiscount += object.amount
            })
            return this.currency((parseFloat(this.totalPersonalisation) + parseFloat(this.totalDelivery) + parseFloat(this.item.subtotal)) + parseFloat(totalDiscount))
        },
        discounts: function () {
            return this.item.adjustments.filter(object => (object.type === 'discount' && object.lineItemId === this.item.id))
        },
        taxes: function () {
            return this.item.adjustments.filter(object => (object.type === 'tax' && object.lineItemId === this.item.id))
        },
        stepTitle: function () {
            return this.$i18n.i18next.t(this.stepTitles['step' + this.activeStep])
        },
        desiredDeliveryDateSelected: {
            get: function () {
                if (this.desiredDeliveryDate.date && this.desiredDeliveryDate.date >= this.calcDesiredDeliveryDateStart) {
                    return this.desiredDeliveryDate.date
                } else {
                    // 1 day after, clone Date object else original date gets changed
                    return this.addWorkDays(new Date(this.calcDesiredDeliveryDateStart), 1);
                }
            },
            set: function (v) {
                this.desiredDeliveryDate.date = v;
            }
        },
        calcDesiredDeliveryDateStart() {
            const today = (this.item.availableFrom) ? new Date(this.item.availableFrom) : new Date();
            if (this.chosenOptions.personalisation) {
                return this.addWorkDays(today, this.chosenOptions.personalisation.deliveryMinDays);
            } else {
                return today;
            }
        },
        locale() {
            return this[window.locale];
        },
        totalDelivery() {
            if (this.deliveryOrderLines.length) {
                let total = 0;
                this.deliveryOrderLines.forEach(object => {
                    if (!object.free) {
                        total += object.price
                    }
                })
                return total
            } else {
                return 0;
            }
        }
    },

    methods: {
        addWorkDays(startDate, days) {
            if (isNaN(days)) {
                console.log("Value provided for \"days\" was not a number");
                return
            }

            if (!(startDate instanceof Date)) {
                console.log("Value provided for \"startDate\" was not a Date object");
                return
            }

            // Get the day of the week as a number (0 = Sunday, 1 = Monday, .... 6 = Saturday)
            const dow = startDate.getDay();
            let daysToAdd = parseInt(days);
            // If the current day is Sunday add one day
            if (dow === 0)
                daysToAdd++;
            // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
            if (dow + daysToAdd >= 6) {
                //Subtract days in current working week from work days
                const remainingWorkDays = daysToAdd - (5 - dow);
                //Add current working week's weekend
                daysToAdd += 2;
                if (remainingWorkDays > 5) {
                    //Add two days for each working week by calculating how many weeks are included
                    daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
                    //Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
                    if (remainingWorkDays % 5 === 0)
                        daysToAdd -= 2;
                }
            }

            startDate.setDate(startDate.getDate() + daysToAdd);
            return startDate;
        },

        nextStep() {
            this.activeStep = this.activeStep + 1
        },

        prevStep() {
            this.activeStep = this.activeStep - 1
        },

        validateForm(activeStep) {
            switch (activeStep) {
                case 1:
                    if (this.chosenOptions.personalisation && this.desiredDeliveryDateSelected) {
                        this.nextStep()
                    } else {
                        EventBus.$emit('show-message', {
                            messageText: this.messages.personalisation,
                            messageType: "error"
                        })
                    }
                    break

                case 2:
                    let qty = 0
                    let qtyValid = false
                    // Total qty check
                    if (this.delivery === "multiple-addresses") {
                        this.shippingAddresses.forEach(object => {
                            qty += parseInt(object.qty)
                        })
                        if (qty !== parseInt(this.item.qty)) {
                            EventBus.$emit('show-message', {
                                messageText: this.messages.addressesQty,
                                messageType: "success"
                            })
                        } else {
                            qtyValid = true
                        }
                    } else {
                        qtyValid = true
                    }

                    if (qtyValid) {
                        if (this.chosenOptions.delivery && this.validStep2) {
                            this.nextStep()
                        } else {
                            EventBus.$emit('show-message', {
                                messageText: this.messages.validateStep2,
                                messageType: "error"
                            })
                        }
                    }

                    break
            }
        },

        validateAddress(addressForm, index) {

            switch (addressForm) {
                case 'single-address':
                    this.$refs[addressForm + '-validation'].validate().then(() => {
                        if (!this.$refs[addressForm + '-validation'].flags.invalid) {
                            this.validStep2 = true
                            this.$set(this.shippingAddress, 'minimized', true)
                            //
                            this.setShippingCost(addressForm)
                            //
                            EventBus.$emit('show-message', {
                                messageText: this.messages.savedAddressSuccess,
                                messageType: "success"
                            })
                        }
                    })
                    break

                case 'multiple-addresses':
                    let form = addressForm + '-' + index + '-validation'
                    this.$refs[form][0].validate().then(() => {
                        if (!this.$refs[form][0].flags.invalid) {
                            this.validStep2 = true
                            this.shippingAddresses[index].minimized = true
                            this.inAddressMode = false
                            // Reset this.shippingAddress
                            this.shippingAddress = this.$options.data().shippingAddress
                            //
                            this.setShippingCost(addressForm)
                            // Show messsage
                            EventBus.$emit('show-message', {
                                messageText: this.messages.savedAddressSuccess,
                                messageType: "success"
                            })
                        }
                    })
                    break

                case 'individual-addresses':
                    this.$refs[addressForm + '-validation'].validate().then(() => {
                        if (!this.$refs[addressForm + '-validation'].flags.invalid) {
                            this.validStep2 = true
                            this.setShippingCost(addressForm)
                            EventBus.$emit('show-message', {
                                messageText: this.messages.savedQtySuccess,
                                messageType: "success"
                            })
                        }
                    })
                    break;

                case 'pickup-lo':
                case 'pickup-ieper':
                    this.validStep2 = true
                    this.setShippingCost('pickup')
                    break;

            }
        },

        showAddress(addressForm, index) {
            switch (addressForm) {
                case 'single-address':
                    this.validStep2 = false
                    this.$set(this.shippingAddress, 'minimized', false)
                    break
                case 'multiple-addresses':
                    this.validStep2 = false
                    this.shippingAddresses[index].minimized = false
                    this.inAddressMode = true
                    break
            }
        },

        addShippingAddress() {
            this.inAddressMode = true
            let shippingAddress = this.shippingAddress
            // Set quantity
            if (this.shippingAddresses.length) {
                let qty = 0
                // Get al quantities
                this.shippingAddresses.forEach(object => {
                    qty += object.qty
                })
                shippingAddress.qty = (this.item.qty - qty)
            } else {
                shippingAddress.qty = this.item.qty
            }
            this.shippingAddresses.push(shippingAddress)
        },

        removeShippingAddress(index) {
            this.inAddressMode = false
            Vue.delete(this.shippingAddresses, index)
        },

        qtyChange(index, $event) {

            let qty = $event.target.value

            if (this.chosenOptions.personalisation) {
                // Determine quantity
                if (parseInt(this.chosenOptions.personalisation.minQty) > parseInt(qty)) {
                    qty = this.chosenOptions.personalisation.minQty
                    EventBus.$emit('show-message', {
                        messageText: this.messages.qtyChanged,
                        messageType: "error"
                    })
                }
            }

            // Set to multiple of personalisation.qtyInCarton
            qty = this.calculateQtyPerCarton(qty)

            this.loading = true

            // Dispatch
            this.$store.dispatch('cart/setQtyLineItem', {
                index: index,
                qty: qty
            }).then(() => {
                this.item = this.$store.state.cart.cart[this.index]
                if (this.delivery) {
                    this.setShippingCost(this.delivery)
                }
                this.loading = false
            })
        },

        showPersonalisationInfo(e) {
            let qty = this.item.qty
            this.loading = true
            if (e.target.value !== '') {
                this.chosenOptions.personalisation = this.options.personalisation[e.target.value]
                if (this.chosenOptions.personalisation.packshot) {
                    Vue.nextTick(() => {
                        this.initCarousel()
                    })
                }
                // Set qty
                if (parseInt(this.chosenOptions.personalisation.minQty) > parseInt(qty)) {
                    qty = this.chosenOptions.personalisation.minQty
                    EventBus.$emit('show-message', {
                        messageText: this.messages.qtyChanged,
                        messageType: "error"
                    })
                }
                // Set to multiple of personalisation.qtyInCarton
                qty = this.calculateQtyPerCarton(qty)
                // Update cart with minimum
                this.$store.dispatch('cart/setQtyLineItem', {
                    index: this.index,
                    qty: qty
                }).then(() => {
                    this.item = this.$store.state.cart.cart[this.index]
                    this.totalPersonalisation = (parseFloat(this.chosenOptions.personalisation.salePrice) * parseInt(this.item.qty))
                    this.deliveryTypes = {}
                    this.setDeliveryTypes(this.chosenOptions.personalisation)
                    this.loading = false
                })
            } else {
                this.chosenOptions.personalisation = ''
                this.totalPersonalisation = 0
                // Update cart with 1
                this.$store.dispatch('cart/setQtyLineItem', {
                    index: this.index,
                    qty: qty
                }).then(() => {
                    this.item = this.$store.state.cart.cart[this.index]
                    this.loading = false
                })
            }
        },

        showDeliveryInfo(e) {
            this.chosenOptions.delivery = this.deliveryTypes[e.target.value]

            // Reset some fields
            this.deliveryPricesInfo = []
            //this.shippingAddress = this.$options.data().shippingAddress
            this.individualQtys = this.$options.data().individualQtys
            this.deliveryOrderLines = []

            // Already split quantity for individual
            this.individualQtys.be = this.item.qty
            this.individualQtys.nl = 0

            if (this.delivery !== "") {
                this.chosenOptions.delivery.options.forEach((optionId, index) => {
                    this.$set(this.deliveryPricesInfo, index, this.options.personalisation[this.personalisation].delivery[optionId])
                })
            }

            // Shortcut to validateAddress
            if(this.delivery === 'pickup-ieper' || this.delivery === 'pickup-lo')
            {
                this.validateAddress(this.delivery, 0)
            }
        },

        recalculateIndividualQtys(e) {
            let el = e.target
            let country = el.dataset.country
            let qty = el.value
            // Set to multiple of personalisation.qtyInCarton
            // qty = this.calculateQtyPerCarton(qty)
            this.individualQtys[country] = qty
            if (country === 'be') {
                this.individualQtys['nl'] = this.item.qty - qty
            } else {
                this.individualQtys['be'] = this.item.qty - qty
            }
        },

        recalculateMultipleQtys(index, e) {
            let qty = e.target.value
            // Set to multiple of personalisation.qtyInCarton
            qty = this.calculateQtyPerCarton(qty)
            this.shippingAddresses[index].qty = qty
        },

        calculateQtyPerCarton(qty) {
            if ((qty % this.options.qtyInCarton) !== 0) {
                qty = Math.ceil((qty / this.options.qtyInCarton)) * this.options.qtyInCarton
                EventBus.$emit('show-message', {
                    messageText: this.messages.qtyChanged,
                    messageType: "error"
                })
            }
            return qty
        },

        setDeliveryTypes(personalisation) {
            const deliveryOptions = Object.entries(personalisation.delivery)
            let localArray = []
            for (const [optionId, option] of deliveryOptions) {
                const deliveryTypes = Object.entries(option.deliveryTypes)
                for (const [typeKey, type] of deliveryTypes) {
                    if (type.slug in this.deliveryTypes) {
                        localArray[type.slug].push(optionId)
                    } else {
                        localArray[type.slug] = []
                        localArray[type.slug].push(optionId)
                    }
                    const deliveryOptions = {
                        slug: type.slug,
                        label: type.title,
                        description: type.description,
                        options: localArray[type.slug]
                    }
                    this.deliveryTypes = Object.assign({}, this.deliveryTypes, {[type.slug]: deliveryOptions})
                }
            }
        },

        setShippingCost(type) {

            let country
            let deliveryInfo
            let deliveryCosts = []

            switch (type) {

                case 'single-address':

                    // Which country was selected?
                    country = this.shippingAddress.country
                    deliveryInfo = this.deliveryPricesInfo.filter(object => object.country.slug === country)

                    let totalPriceForCountries = {}
                    totalPriceForCountries.be = 0
                    totalPriceForCountries.nl = 0

                    if (deliveryInfo[0] !== undefined) {

                        let alreadyShipping = false
                        // Get other products in cart and check for same deliveryMethod
                        // Check if there is another product with deliveryOption single-address
                        // If there is do not add shipping method
                        this.$store.state.cart.cart.forEach(lineItem => {
                            if('orderOptions' in lineItem && this.item.id !== lineItem.id) {
                                // Found orderOptions of other configuration
                                // Is this also an single-address product in the same country?
                                if(lineItem.orderOptions.configurationObject.deliveryType == 'single-address') {
                                    // Now get country and check country with choosen country in new configuration
                                    if(lineItem.orderOptions.configurationObject.address.country === country) {
                                        // Now check if addresses are equal
                                        if(isEqual(lineItem.orderOptions.configurationObject.address, this.shippingAddress)) {
                                            // Configure delivery options
                                            alreadyShipping = true
                                        }
                                    }
                                }
                            }
                        })

                        if(!alreadyShipping) {
                            this.deliveryOrderLines = [{
                                label: deliveryInfo[0].title,
                                price: deliveryInfo[0].salePrice,
                                free: (parseInt(this.$store.state.cart.commerceCart.totalTaxablePrice) > parseInt(deliveryInfo[0].freeDelivery))
                            }]
                        } else {
                            this.deliveryOrderLines = [{
                                label: deliveryInfo[0].title,
                                price: deliveryInfo[0].salePrice,
                                free: true
                            }]
                        }

                    } else {
                        this.deliveryOrderLines = [{
                            label: null,
                            price: null,
                            free: false
                        }]
                    }
                    break

                case 'multiple-addresses':
                    let addressesByCountry = []
                    // Which country was selected?
                    for (const key in this.shippingAddresses) {
                        let address = this.shippingAddresses[key]
                        if (address.country in addressesByCountry) {
                            addressesByCountry[address.country] += 1
                        } else {
                            addressesByCountry[address.country] = 1
                        }
                    }
                    // Get costs per country and quantity
                    for (const id in addressesByCountry) {
                        deliveryInfo = this.deliveryPricesInfo.filter(object => object.country.slug === id)
                        if (deliveryInfo[0] !== undefined) {
                            deliveryCosts.push({
                                label: deliveryInfo[0].title + " (" + addressesByCountry[id] + " x " + this.currency(deliveryInfo[0].salePrice) + ")",
                                price: (this.item) ? Number(deliveryInfo[0].salePrice) * Number(addressesByCountry[id]) : 0,
                                free: (parseInt(this.$store.state.cart.commerceCart.totalTaxablePrice) > parseInt(deliveryInfo[0].freeDelivery))
                            })
                        }
                    }
                    this.deliveryOrderLines = deliveryCosts
                    break

                case 'individual-addresses':
                    // Get costs per country and quantity
                    for (const id in this.individualQtys) {
                        deliveryInfo = this.deliveryPricesInfo.filter(object => object.country.slug === id)
                        if (deliveryInfo[0] !== undefined) {
                            deliveryCosts.push({
                                label: deliveryInfo[0].title + " (" + this.individualQtys[id] + " x " + this.currency(deliveryInfo[0].salePrice) + ")",
                                price: Number(deliveryInfo[0].salePrice) * Number(this.individualQtys[id]),
                                free: false
                            })
                        }
                    }
                    this.deliveryOrderLines = deliveryCosts
                    break

                case 'pickup':
                    country = 'be'
                    deliveryInfo = this.deliveryPricesInfo.filter(object => object.country.slug === country)

                    deliveryCosts.push({
                        label: deliveryInfo[0].title,
                        price: 0,
                        free: true
                    })
                    this.deliveryOrderLines = deliveryCosts
                    break;
            }
        },

        initCarousel() {
            let carousel = document.getElementById('personalisation-swiper')
            if (carousel) {
                new Swiper(carousel, {
                    speed: 400,
                    spaceBetween: 36,
                    autoplay: {
                        enabled: false
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    }
                })
            }
        },

        async getPersonalisationOptions() {
            this.loading = true
            let apiUrl = '/api/shop/products/'
            let localizedUrl = window.locale !== 'nl' ? '/' + window.locale + apiUrl : apiUrl
            await axios.get(
                apiUrl + this.item.snapshot.productId + '.json')
                .then(response => {
                    this.options = response.data

                    // If we only have one option, preselect it
                    if(Object.keys(this.options.personalisation).length == 1) {
                        let keys = Object.keys(this.options.personalisation)
                        this.personalisation = keys[0]
                        this.chosenOptions.personalisation = this.options.personalisation[keys[0]]
                        if (!this.item.hasOwnProperty('orderOptions')) {
                            this.setDeliveryTypes(this.options.personalisation[keys[0]])
                        }
                    }

                    // Does out item already have a filled Configuration Object?
                    if ('orderOptions' in this.item) {
                        this.setOrderOptions(this.item.orderOptions)
                    }
                })
            this.loading = false
        },

        savePersonalisation() {

            // Prep personalsation object
            let selectedOptions = {
                personalisation: this.chosenOptions.personalisation,
                delivery: this.chosenOptions.delivery,
                deliveryFree: this.deliveryOrderLines[0].free,
                deliveryType: this.chosenOptions.delivery.slug,
                addresses: this.shippingAddresses,
                address: this.shippingAddress,
                individualQtys: this.individualQtys,
                lineItem: this.item,
                desiredDeliveryDate: this.desiredDeliveryDateSelected
            }

            axios.post(
                '/checkout/save-personalisation',
                {'options': selectedOptions}
                ).then(response => {

                    // Change qty for datalayer
                    this.productDataLayer.quantity = this.item.qty

                    // Fire datalayer
                    window.dataLayer.push({ ecommerce: null });
                    window.dataLayer.push({
                        event: 'add_to_cart',
                        ecommerce: {
                            items: [this.productDataLayer]
                        }
                    });

                    this.$store.dispatch('cart/getCart').then(() => {
                    this.$emit('close')

                    EventBus.$emit('show-message', {
                        messageText: this.messages.savedSuccess,
                        messageType: "success"
                    })
                })
            })
        },

        setOrderOptions(orderOptions) {

            this.chosenOptions.personalisation = orderOptions.configurationObject.personalisation
            this.chosenOptions.delivery = orderOptions.configurationObject.delivery
            this.shippingAddresses = Array.isArray(orderOptions.configurationObject.shippingAddresses) ? Array.isArray(orderOptions.configurationObject.shippingAddresses) : []
            this.shippingAddress = orderOptions.configurationObject.address
            this.individualQtys = orderOptions.configurationObject.individualQtys
            this.personalisation = 'P' + orderOptions.configurationObject.personalisation.id
            this.delivery = orderOptions.configurationObject.deliveryType

            // Set step 2 as valid
            this.validStep2 = true

            this.setDeliveryTypes(orderOptions.configurationObject.personalisation)

            // Set delivery price info
            this.chosenOptions.delivery.options.forEach((optionId, index) => {
                this.$set(this.deliveryPricesInfo, index, this.options.personalisation[this.personalisation].delivery[optionId])
            })

            this.totalPersonalisation = (this.chosenOptions.personalisation.salePrice * this.item.qty)
            this.setShippingCost(orderOptions.configurationObject.deliveryType)
        },

        currency(price) {
            return new Intl.NumberFormat('nl-NL', {style: 'currency', currency: 'EUR'}).format(price)
        }
    },

    mounted() {
        this.item = this.$store.state.cart.cart[this.index]

        // Set shippingAddress to available BillingAddress in cart
        if(this.$store.state.cart.commerceCart.billingAddressId !== '') {
            axios.post(
                '/actions/commerce/addresses/get-address-by-id',
                { id: this.$store.state.cart.commerceCart.billingAddressId }
            ).then(response => {
                if(response.status === 200 && response.data.success) {
                    this.shippingAddress.firstName = response.data.address.firstName
                    this.shippingAddress.lastName = response.data.address.lastName
                    this.shippingAddress.address1 = response.data.address.address1
                    this.shippingAddress.address2 = response.data.address.address2
                    this.shippingAddress.address3 = response.data.address.address3
                    this.shippingAddress.zipCode = response.data.address.zipCode
                    this.shippingAddress.city = response.data.address.city
                    this.shippingAddress.country = response.data.address.countryIso.toLowerCase()
                    this.shippingAddress.businessName = response.data.address.businessName
                }
            })
        }

        this.messages = {
            personalisation: this.$i18n.i18next.t("Gelieve een personalisatie en/of gewenste leveringsdatum te kiezen"),
            addressesQty: this.$i18n.i18next.t("De hoeveelheden ingegeven bij de individuele adressen komt niet overeen met het totale aantal."),
            validateStep2: this.$i18n.i18next.t("Gelieve een levering te kiezen en alle velden aan te vullen"),
            savedAddressSuccess: this.$i18n.i18next.t("Het adres is succesvol bewaard"),
            savedQtySuccess: this.$i18n.i18next.t("De aantallen zijn succesvol bewaard"),
            qtyChanged: this.$i18n.i18next.t("De hoeveelheid werd aangepast naar de minimum hoeveelheid voor deze personalisatie"),
            savedSuccess: this.$i18n.i18next.t("De personalisatie is bewaard!")
        }

        this.$store.dispatch('cart/setShippingMethod').then(() => {
            this.getPersonalisationOptions()
        })

    }
}
</script>

<style scoped>
#personalisation-swiper {
    @apply overflow-visible;
}

#personalisation-swiper .swiper-pagination-bullets {
    bottom: -30px;
}

.form .address--form input:not([type="submit"]),
.form .address--form textarea {
    @apply px-2 py-2 w-full;
}

.form .address--form .select-styling select {
    @apply py-2 pl-2;
}

.vm--overlay {
    height: 0 !important;
}

.vm--container {
    /* add to keep a modal backdrop */
    background-color: rgba(0, 0, 0, 0.85) !important;
}

.alert-box {
    @apply p-8 bg-blue-200 mt-8;
}
</style>
